define('cmp-dashboard/components/shared/helpers/tools/share-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    toast: Ember.inject.service(),

    tagName: 'button',
    classNames: 'btn btn--white',

    click: function click() {
      var temp_input = document.createElement('input'),
          text = window.location.href;

      document.body.appendChild(temp_input);
      temp_input.value = text;
      temp_input.select();
      document.execCommand('copy');
      document.body.removeChild(temp_input);

      this.get('toast').info('URL copied to clipboard');
    }
  });
});