define('cmp-dashboard/components/shared/tours/service-reminders-main', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              localStorage['serviceReminderMainTourCount_' + user_id] = 100; // Make count high so it doesn't show again
              tour.cancel();
            }
          }],
          title: 'Service Reminders',
          text: ['Click through these steps to learn about contacting customers for service reminders.']
        }
      }, {
        id: 'Progress',
        options: {
          attachTo: '#contact-progress bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Contact Progress',
          text: ['This bar shows the current percentage of service reminder contracts that were contacted. As you go through the list contacting customers, this will increase.']
        }
      }, {
        id: 'layout-toggle',
        options: {
          attachTo: '#layout-toggle bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Layout Toggle',
          text: ['By default, you will see the service reminders in a grid view. This view offers more information than the table view. However, if you would prefer the table layout, click here to switch between the two layouts.']
        }
      }, {
        id: 'sorting',
        options: {
          attachTo: '.search-filters__sorting bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Sorting Results',
          text: ['You can change the way the service reminders are sorted using this dropdown.']
        }
      }, {
        id: 'reports',
        options: {
          attachTo: '#reports-link bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Reports',
          text: ['To view reports about your service reminders, click here.']
        }
      }, {
        id: 'contact-card',
        options: {
          attachTo: '.contact-card top',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Service Reminder Contact',
          text: ['This contact tile shows you information about a service reminder. It shows you the customer information. The last reminder sent date is also shown under the last visit date. If a contract has CXT feedback it is shown in the upper right of the tile.']
        }
      }, {
        id: 'reports',
        options: {
          attachTo: {
            element: '.contact-card__options',
            on: 'top'
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }],
          title: 'Contact Options',
          text: ['These are the methods you can use to contact the service reminder customer. By default, only phone is available, but if the customer has an email or cell phone, the <b>email</b> and <b>text message</b> options will be available as well.']
        }
      }];

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['serviceReminderMainTourCount_' + user_id]) {
          localStorage['serviceReminderMainTourCount_' + user_id]++;
        } else {
          localStorage['serviceReminderMainTourCount_' + user_id] = 1;
        }
      });
    }
  });
});