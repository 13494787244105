define('cmp-dashboard/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-cli-pagination/remote/route-mixin'], function (exports, _authenticatedRouteMixin, _routeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeMixin.default, {
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        window.location.replace("/login");
      } else if (!localStorage.dealer) {
        this.transitionTo('dealers');
      }
    },
    model: function model() {
      return null;
    }
  });
});