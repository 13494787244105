define('cmp-dashboard/models/claimregister', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global moment:true */
  var inflector = _emberInflector.default.inflector;

  inflector.irregular("claimregister", "claimregisters");

  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    count: _emberData.default.attr('number'),
    amt: _emberData.default.attr('number'),
    enter_date: _emberData.default.attr('utc'),
    mntly_accnt_upd: _emberData.default.attr('string'),
    email_notified: _emberData.default.attr('number'),
    upsale: _emberData.default.attr('number'),
    historical: _emberData.default.attr('number'),
    updated_historical: _emberData.default.attr('number'),
    sponsor_bills_id: _emberData.default.attr('number'),
    cleared_date: _emberData.default.attr('utc'),
    cleared_by: _emberData.default.attr('string'),
    payment_method: _emberData.default.attr('string'),
    payment_ref_num: _emberData.default.attr('string'),
    split_parent: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', {
      async: true
    }),
    inst_id: _emberData.default.belongsTo('inst', {
      async: true
    }),

    claims: _emberData.default.hasMany('claim', {
      async: true
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    formatted_status: Ember.computed('status', function () {
      var formatted = '';
      switch (this.get('status')) {
        case 'P':
        case 'p':
          formatted = 'Processed';
          break;
        case 'C':
        case 'c':
          formatted = 'Claim Posted?';
          break;
      }
      return formatted;
    }),

    formatted_amount: Ember.computed('amt', function () {
      return this.get('amt').toFixed(2);
    })
  });
});