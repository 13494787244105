define('cmp-dashboard/controllers/application', ['exports', 'moment', 'cmp-dashboard/config/environment'], function (exports, _moment, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.service(),
    session: Ember.inject.service(),

    navOpen: false,
    dashboardSection: null,
    showUserDetails: false,

    init: function init() {
      this._super();

      var restrictedRoles = [];
      this.set('restrictedRoles', restrictedRoles);

      // Setup nav dashboard links
      var dashboards = {
        title: 'dashboards',
        items: [{
          icon: 'shared/partials/icons/admin-svg',
          label: 'Admin',
          url: '/admin/',
          tooltipLabel: 'Admin Dashboard',
          useRouter: false,
          visibleFor: ['admin'],
          userPermission: 6
        }, {
          icon: 'shared/partials/icons/office-svg',
          label: 'Office',
          url: '/office/',
          tooltipLabel: 'Office Dashboard',
          useRouter: false,
          hiddenFor: ['service', 'sales']
        }, {
          icon: 'shared/partials/icons/reports-svg',
          label: 'Reports',
          url: '/reports/',
          tooltipLabel: 'Reports Dashboard',
          useRouter: false
        }, {
          icon: 'shared/partials/icons/wrench-svg',
          label: 'Service',
          url: '/service/',
          tooltipLabel: 'Service Dashboard',
          useRouter: false
        }, {
          icon: 'shared/partials/icons/home-svg',
          label: 'Home',
          url: '/',
          tooltipLabel: 'Home Dashboard',
          active: true,
          useRouter: false
        }]
      };
      this.set('dashboardSection', dashboards);
    },


    pagesSection: Ember.computed('currentRouteName', function () {
      var currentRoute = this.get('currentRouteName') ? this.get('currentRouteName') : '';

      var pages = {
        title: 'tasks',
        items: [{
          icon: 'shared/partials/icons/pages-home-overview-svg',
          label: 'Overview',
          url: 'index',
          active: currentRoute !== 'dealers',
          useRouter: true
        }]
      };

      return pages;
    }),

    lastModified: Ember.computed(function () {
      return (0, _moment.default)(document.lastModified, 'MM/DD/YYYY hh:mm:ss').format('MMMM Do, YYYY');
    }),

    dashboardClasses: Ember.computed('user.role', function () {

      var dashboardClasses = '';

      if (this.get('user')) {
        var role = this.get('user').get('role');
        if (role === 'office_staff') {
          dashboardClasses += 'side-nav-hidden';
        }
      }

      if (_environment.default.environment === 'development') {
        dashboardClasses += ' dev-site';
      }

      return dashboardClasses;
    }),

    domain: function domain() {
      var role = this.get('user.role');

      if (window.location.host === 'localhost:4200' || window.location.host === 'quality.maintenanceprogram.com') {
        return 'http://devjimmy.maintenanceprogram.com';
      } else {
        if (role === 'admin') {
          return 'https://reports.maintenanceprogram.com';
        } else {
          return 'https://www.maintenanceprogram.com';
        }
      }
    },

    agent_logo: Ember.computed('dealer.agents_id.{id,hide_welcome_logo}', function () {
      var hideLogo = Ember.isEmpty(this.get('dealer.agents_id.hide_welcome_logo')) ? true : this.get('dealer.agents_id.hide_welcome_logo'); // Default to hidden if undefined

      if (hideLogo) {
        return null;
      } else {
        return 'https://images.maintenanceprogram.com/logos/agent' + this.get('dealer.agents_id.id') + '.png';
      }
    }),

    sponsor_logo: Ember.computed('dealer.agents_id.sponsors_id.id', function () {
      var sponsor_id = this.get('dealer.agents_id.sponsors_id.id');
      if (sponsor_id) {
        return 'https://images.maintenanceprogram.com/logos/sponsor_nav' + sponsor_id + '.png';
      } else {
        return null;
      }
    }),

    officeAccess: Ember.computed('user.role', function () {
      var role = this.get('user.role');

      return role !== 'sales' && role !== 'service';
    }),

    serviceAccess: Ember.computed('user.role', function () {
      var role = this.get('user.role');

      return role !== 'sales';
    }),

    actions: {
      closeLoginPrompt: function closeLoginPrompt() {
        this.set('showLoginPrompt', false);
      },
      showUserDetails: function showUserDetails() {
        this.set('showUserDetails', true);
      },
      closeUserDetails: function closeUserDetails() {
        this.set('showUserDetails', false);
      },
      openFeedback: function openFeedback() {
        this.set('feedbackOpen', true);
      },
      navTourComplete: function navTourComplete() {
        sessionStorage.showPagesTour = true;
        this.get('application').set('navTourClosed', true);
      }
    }
  });
});