define('cmp-dashboard/components/shared/helpers/setup-user', ['exports', '@sentry/browser', 'cmp-dashboard/config/environment'], function (exports, _browser, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      var authenticated = this.get('isAuthenticated');
      var controller = this.get('applicationController');
      var application = this.get('application');
      var restricted = this.get('restrictedRoles');

      if (authenticated && !application.get('user')) {
        this.get('store').queryRecord('user', {
          who: 1
        }).then(function (user) {
          controller.set('user', user);
          application.set('user', user);

          // Configure user for Sentry logging
          _browser.configureScope(function (scope) {
            scope.setUser({
              "email": user.get('email')
            });
          });

          var user_id = user.get('id').substring(0, 7);

          // If user has not seen the new nav tutorial
          // show that instead of the welcome prompt
          if (sessionStorage.showLoginPrompt === "true" && (!sessionStorage.redirecting || sessionStorage.redirecting === 'false' || self.get('dash') !== 'home')) {

            if ((!localStorage['navTourCount_' + user_id] || localStorage['navTourCount_' + user_id] < 1) && !localStorage['disableNavTour_' + user_id] && _environment.default.env !== 'demo') {
              self.set('application.showNavTour', true);
            } else {
              self.get('showLoginPrompt')(true);
            }
            sessionStorage.showLoginPrompt = false;
          }

          if (sessionStorage.showPagesTour === "true") {
            application.set('navTourClosed', true);
          }

          var role = user.get('role');
          if (restricted.indexOf(role) === -1) {
            controller.set('noaccess', false);
          } else {
            controller.set('noaccess', true);
            self.get('forbidden')();
          }

          self.get('refresh')();
        }).catch(function (error) {
          var jsonError = error.errors ? error.errors[0] : error;

          if (jsonError.title && jsonError.title.indexOf('TokenExpiredError') === -1) {
            _browser.captureMessage('Error retrieving user data => ' + jsonError.title + ': ' + jsonError.detail); // Remove when legacy is turned off
          }
        });
      }
    }
  });
});