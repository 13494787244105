define('cmp-dashboard/models/contactpreference', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    update_date: _emberData.default.attr('date'),
    do_not_call: _emberData.default.attr('number'),
    do_not_email: _emberData.default.attr('number'),
    do_not_mail: _emberData.default.attr('number'),
    do_not_text: _emberData.default.attr('number'),
    stop_reply: _emberData.default.attr('number'),
    customers_id: _emberData.default.attr('number'),

    contract_id: _emberData.default.belongsTo('contract', { async: true }),
    user_id: _emberData.default.belongsTo('user', { async: true }),

    formatted_update_date: Ember.computed('update_date', function () {
      return moment(this.get('update_date')).format('M/DD/YYYY');
    }),

    formatted_call_time: Ember.computed('update_date', function () {
      return moment(this.get('update_date')).format('h:mm A');
    })
  });
});