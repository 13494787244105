define('cmp-dashboard/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    base_url: _emberData.default.attr('string'),
    internal: _emberData.default.attr('number'),
    last_ran: _emberData.default.attr('date'),
    last_ran_proc: _emberData.default.attr('date'),
    req_group_priv: _emberData.default.attr('number'),
    print: _emberData.default.attr('number'),
    mailto: _emberData.default.attr('string'),
    new_url: _emberData.default.attr('string'),
    legacy_clicks: _emberData.default.attr('number'),

    report_name: _emberData.default.hasMany('reportsname', {
      async: true
    }),

    report_classes: _emberData.default.hasMany('reportsclass', {
      async: true
    }),

    report_categories: _emberData.default.hasMany('reportscategoriesmap', {
      async: true
    }),

    report_overrides: _emberData.default.hasMany('userreportoverride', {
      async: true
    }),

    report_roles: _emberData.default.hasMany('aclroletmplreport', {
      async: true
    }),

    isNew: Ember.computed('new_url', function () {
      var new_url = this.get('new_url');

      return new_url && new_url.length > 0;
    }),

    name: Ember.computed('report_name.@each.name', function () {
      var report_name = this.get('report_name').filterBy('language', 'EN')[0];

      if (report_name) {
        return report_name.get('name');
      } else {
        return null;
      }
    }),

    allowed_roles: Ember.computed('report_roles.@each.role', function () {
      var report_roles = this.get('report_roles');

      var allowed_roles = report_roles.map(function (report_role) {
        return report_role.get('role');
      });

      var unique_roles = allowed_roles.filter(function (value, index) {
        return allowed_roles.indexOf(value) === index;
      });

      return unique_roles;
    }),

    allow_overrides: Ember.computed('report_overrides.@each.web_users_id', function () {
      var report_overrides = this.get('report_overrides');

      var overrides = report_overrides.map(function (override) {
        if (override.get('access') === 'ALLOW') {
          return override.get('web_users_id');
        }
      });

      return overrides;
    }),

    deny_overrides: Ember.computed('report_overrides.@each.web_users_id', function () {
      var report_overrides = this.get('report_overrides');

      var overrides = report_overrides.map(function (override) {
        if (override.get('access') === 'DENY') {
          return override.get('web_users_id');
        }
      });

      return overrides;
    })
  });
});