define('cmp-dashboard/components/shared/helpers/form/contract/dealer-comments-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    toast: Ember.inject.service(),

    tagName: 'li',
    classNames: 'dealer-comments__item',
    classNameBindings: ['editing:dealer-comments__item--editing'],

    editing: false,
    alertMessage: 'Are you sure you want to delete this comment?',

    actions: {
      toggleEditing: function toggleEditing() {
        this.toggleProperty('editing');
      },
      saveComment: function saveComment() {
        var self = this;
        var comment = this.get('comment');

        var dealer = localStorage.dealer;
        localStorage.dealer = this.get('contract').get('dealers_id').get('id');

        comment.save().then(function () {
          localStorage.dealer = dealer;
          self.set('editing', false);
          self.get('toast').success('Comment was updated successfully');
        }, function (errors) {
          localStorage.dealer = dealer;
          errors.errors.forEach(function (error) {
            if (error.status) {
              self.get('toast').error(error.title + ': ' + error.status + ' - ' + error.detail);
            } else if (error.message) {
              self.get('toast').error(error.message);
            }
          });
          self.get('toast').error('There was a problem saving the comment. Please try again or contact support@performanceadmin.com');
        });
      },
      deleteComment: function deleteComment() {
        this.set('pageAlert', true);
      },
      cancelDelete: function cancelDelete() {
        this.set('pageAlert', false);
      },
      confirmDelete: function confirmDelete() {
        var self = this;
        var comment = this.get('comment');

        localStorage.contract = this.get('contract').get('id');

        var dealer = localStorage.dealer;
        localStorage.dealer = this.get('contract').get('dealers_id').get('id');

        comment.destroyRecord().then(function () {
          localStorage.dealer = dealer;
          self.get('toast').success('Comment was deleted successfully');
        }, function (errors) {
          localStorage.dealer = dealer;
          errors.errors.forEach(function (error) {
            if (error.status) {
              self.get('toast').error(error.title + ': ' + error.status + ' - ' + error.detail);
            } else if (error.message) {
              self.get('toast').error(error.message);
            }
          });
          self.get('toast').error('There was a problem saving the comment. Please try again or contact support@performanceadmin.com');
        });
      }
    }
  });
});