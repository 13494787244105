define('cmp-dashboard/models/summarylostsoul', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    calls: _emberData.default.attr('number'),
    lost_souls: _emberData.default.attr('number'),
    saved_souls: _emberData.default.attr('number'),
    new_lost_souls: _emberData.default.attr('number'),
    start_date: _emberData.default.attr('date'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contract: _emberData.default.belongsTo('contract', { async: true })
  });
});