define('cmp-dashboard/helpers/fa-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.faIcon = faIcon;
  function faIcon(params, hash) {

    var icon = '<i class=\'fa fa-' + params[0];
    if (hash) {
      if (hash.class) {
        icon += " " + hash.class;
      }
    }

    icon += '\'></i>';

    return new Ember.String.htmlSafe(icon);
  }

  exports.default = Ember.Helper.helper(faIcon);
});