define('cmp-dashboard/models/feedback', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    message: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),
    social_link: _emberData.default.attr('string'),
    email: _emberData.default.attr('string')
  });
});