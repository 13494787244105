define('cmp-dashboard/router', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('auth');
    this.route('dealers');
    this.route('setup');
    this.route('home');
    this.route('error');
  });

  Router.reopen({
    notifyGoogleAnalytics: Ember.on('didTransition', function () {
      // Don't send page analytics if on /auth URL.  Contains user credentials
      if (this.get('url').indexOf('/auth') !== -1) {
        return;
      }
      if (typeof ga !== 'function') {
        return;
      }
      return ga('send', 'pageview', {
        'page': '/office/#' + this.get('url'),
        'title': '/office/#' + this.get('url')
      });
    })
  });

  exports.default = Router;
});