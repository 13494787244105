define("cmp-dashboard/helpers/pretty-print-number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.prettyPrintNumber = prettyPrintNumber;
  function prettyPrintNumber(params) {
    var parts = params.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  exports.default = Ember.Helper.helper(prettyPrintNumber);
});