define('cmp-dashboard/models/contractregister', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global moment:true */
  var inflector = _emberInflector.default.inflector;

  inflector.irregular("contractregister", "contractregisters");

  exports.default = _emberData.default.Model.extend({
    reg_type: _emberData.default.attr('string'),
    payment_method: _emberData.default.attr('string'),
    total_contract: _emberData.default.attr('number'),
    total_amount: _emberData.default.attr('number'),
    total_taxes: _emberData.default.attr('number'),
    check_number_int: _emberData.default.attr('number'),
    check_number: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('utc'),
    submit_date: _emberData.default.attr('date'),
    transfer_date: _emberData.default.attr('date'),
    imported: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    finished: _emberData.default.attr('string'),
    viewed: _emberData.default.attr('string'),
    transferred: _emberData.default.attr('string'),
    TP_batch_number: _emberData.default.attr('string'),
    users_id: _emberData.default.attr('string'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    //contract_register_id: DS.belongsTo('contractregister', { async: true }),
    //users_id: DS.belongsTo('user', { async: true }),
    //sponsor_bills_id: DS.belongsTo('sponsorbill', { async: true }),
    //approval_sponsor_bills_id: DS.belongsTo('sponsorbill', { async: true }),

    contracts: _emberData.default.hasMany('contract', { async: true }),
    payments: _emberData.default.hasMany('contractregisterpayment', { async: true }),

    // contract_prices: computed.mapBy('contacts', 'id'),
    //
    // contract_cost: computed.sum('contract_prices'),

    status: Ember.computed('viewed', 'finished', function () {
      return this.get('viewed') === "N" ? "Pending" : this.get('finished') === "N" ? "Printing" : "Processed";
    }),

    contract_cost: Ember.computed('contracts.@each.dealer_remittance', function () {
      var contracts = this.get('contracts');
      var sum = 0;

      contracts.forEach(function (contract) {
        sum += contract.get('dealer_remittance');
      });

      return sum.toFixed(2);
    }),

    total_dealer_remittance: Ember.computed('contracts.@each.pac_remittance', 'total_taxes', function () {
      var contracts = this.get('contracts');
      var sum = 0;

      contracts.forEach(function (contract) {
        sum += contract.get('pac_remittance'); // pac_remittance == register_item.contract_cost
      });

      sum += this.get('total_taxes');

      return sum.toFixed(2);
    }),

    total_pac_remittance: Ember.computed('contracts.@each.pac_remittance', function () {
      var contracts = this.get('contracts');
      var sum = 0;

      contracts.forEach(function (contract) {
        sum += contract.get('pac_remittance');
      });

      return sum.toFixed(2);
    }),

    remittance: Ember.computed('total_dealer_remittance', 'total_pac_remittance', function () {
      var dealer_remittance = this.get('total_dealer_remittance');
      var pac_remittance = this.get('total_pac_remittance');

      if (dealer_remittance === pac_remittance) {
        return pac_remittance;
      } else {
        return dealer_remittance;
      }
    }),

    remitted: Ember.computed('total_dealer_remittance', 'total_pac_remittance', 'payments.@each.paid_recvd', function () {
      var who = this.get('total_dealer_remittance') === this.get('total_pac_remittance') ? 'pac' : 'dealer';

      var payments = this.get('payments');
      var remitted = 0;

      if (who === 'pac') {
        payments = payments.filterBy('remittance_type', 'P');
      } else {
        payments = payments.filterBy('remittance_type', 'D');
      }

      payments.forEach(function (payment) {
        if (payment.get('paid_recvd') === 'R') {
          remitted += payment.get('check_amount');
        } else if (payment.get('paid_recvd') === 'P') {
          remitted -= payment.get('check_amount');
        }
      });

      return remitted;
    }),

    formatted_remitted: Ember.computed('remitted', function () {
      var remitted = parseFloat(this.get('remitted'));

      if (!remitted) {
        remitted = 0;
      }

      return remitted.toFixed(2);
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    formatted_month_year: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MMMM YYYY');
    }),

    formatted_contract_cost: Ember.computed('contract_cost', function () {
      var contract_cost = parseFloat(this.get('contract_cost'));

      if (!contract_cost) {
        contract_cost = 0;
      }

      return contract_cost.toFixed(2);
    }),

    formatted_total_amount: Ember.computed('total_amount', function () {
      var total_amount = parseFloat(this.get('total_amount') / 100);

      if (!total_amount) {
        total_amount = 0;
      }

      return total_amount.toFixed(2);
    }),

    balance: Ember.computed('remittance', 'total_amount', function () {
      var cost = this.get('remittance');
      var total = this.get('total_amount') / 100;

      return cost - total;
    }),

    formatted_balance: Ember.computed('balance', function () {
      var balance = this.get('balance');

      return balance.toFixed(2);
    })
  });
});