define('cmp-dashboard/authenticators/custom', ['exports', 'cmp-dashboard/config/environment', 'ember-simple-auth/authenticators/base'], function (exports, _environment, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    tokenEndpoint: _environment.default.APP.API_HOST + '/auth',
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    /*jshint unused: vars */
    authenticate: function authenticate(options) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (options.token) {
          var currentAuth = {
            success: true,
            message: "Login successful.",
            token: options.token
          };

          localStorage.currentAuth = JSON.stringify(currentAuth);

          resolve({
            token: options.token
          });
        } else {
          Ember.$.ajax({
            url: _this.tokenEndpoint,
            type: 'POST',
            data: JSON.stringify({
              email: options.email,
              password: options.password
            }),
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          }).then(function (response) {
            Ember.run(function () {
              if (response.success) {

                var _currentAuth = {
                  success: true,
                  message: "Login successful.",
                  token: response.token
                };

                localStorage.currentAuth = JSON.stringify(_currentAuth);
                resolve({
                  token: response.token
                });
              } else {
                reject(response.message);
              }
            });
          }, function (xhr) {
            var response = xhr.responseText;
            Ember.run(function () {
              reject(response);
            });
          });
        }
      });
    },

    invalidate: function invalidate() {
      localStorage.removeItem('email');
      localStorage.removeItem('dealer');
      localStorage.removeItem('sponsor');
      localStorage.removeItem('report_sponsor');
      localStorage.removeItem('report_agent');
      localStorage.removeItem('contract');
      localStorage.removeItem('currentAuth');
      sessionStorage.clear();
      return Ember.RSVP.resolve();
    }
  });
});