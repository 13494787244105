define('cmp-dashboard/models/sched', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    term_months: _emberData.default.attr('number'),
    term_miles: _emberData.default.attr('number'),

    active: _emberData.default.attr('string'),
    active_date: _emberData.default.attr('date'),
    pacfee: _emberData.default.attr('number'),
    sponsor_comm: _emberData.default.attr('number'),
    agent_comm: _emberData.default.attr('number'),
    level: _emberData.default.attr('string'),
    pass: _emberData.default.attr('number'),
    fi_comm: _emberData.default.attr('number'),
    int_miles: _emberData.default.attr('number'),
    visits: _emberData.default.attr('number'),
    m: _emberData.default.attr('string'),
    l: _emberData.default.attr('string'),
    other_class: _emberData.default.attr('number'),
    admin_tot: _emberData.default.attr('number'),
    remind_msg: _emberData.default.attr('string'),
    letter_msg: _emberData.default.attr('string'),
    letter_bg: _emberData.default.attr('string'),
    email_l_msg: _emberData.default.attr('string'),
    email_m_msg: _emberData.default.attr('string'),
    remind_cp_desc: _emberData.default.attr('string'),
    renew: _emberData.default.attr('number'),
    referral_commission: _emberData.default.attr('number'),
    referral_agent_commission: _emberData.default.attr('number'),
    referral_commission_expire_date: _emberData.default.attr('date'),
    historical: _emberData.default.attr('number'),
    updated_historical: _emberData.default.attr('number'),
    upgrade: _emberData.default.attr('number'),
    TP_plan_id: _emberData.default.attr('string'),
    consulting_fee: _emberData.default.attr('number'),
    claims_only: _emberData.default.attr('number'),
    account_rep_commission: _emberData.default.attr('number'),
    car_type_reminders: _emberData.default.attr('string'),
    parent_schedule_id: _emberData.default.attr('number'),
    cost_of_insurance: _emberData.default.attr('number'),
    pack: _emberData.default.attr('number'),
    agents_sched_id: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    sched_types_id: _emberData.default.belongsTo('schedt', { async: true }),

    instscheds: _emberData.default.hasMany('instsched', { async: true }),
    inst_id: _emberData.default.belongsTo('inst', { async: true }),
    tps: _emberData.default.hasMany('tp', { async: true }),

    schedule_name: Ember.computed.alias('sched_types_id.sched_type'),
    schedule_id: Ember.computed.alias('sched_types_id.id'),
    dealer_id: Ember.computed.alias('dealer.id'),

    coupons: _emberData.default.hasMany('coupon', { async: true }),

    isActive: Ember.computed('active', function () {
      return this.get('active') === 'Y' ? true : false;
    }),

    name: Ember.computed('term_months', 'term_miles', 'sched_types_id.sched_type', 'formatted_dealer_remittance', function () {
      var sched_type = this.get('sched_types_id').get('sched_type');
      var formatted_dealer_remittance = this.get('formatted_dealer_remittance');

      /*if (!this.get("sched_types_id").get("isFulfilled")) {
       sched_type = "Loading...";
      }*/

      return this.get('term_months') + ' mo / ' + this.get('term_miles') + ' mi / ' + sched_type + ' / $' + formatted_dealer_remittance;
    }),

    term: Ember.computed('term_months', 'term_miles', function () {
      return this.get('term_months') + ' months / ' + this.get('term_miles') + ' miles';
    }),

    total_tp_cost: Ember.computed('tps.@each.dealercost', function () {
      var tps = this.get("tps");
      var ret = 0;

      tps.forEach(function (tp) {
        ret += tp.get("dealercost");
      });

      return ret / 100;
    }),

    reserve_amount: Ember.computed('instscheds.@each.reserve_amount', function () {
      var instscheds = this.get("instscheds");
      var ret = 0;

      instscheds.forEach(function (tp) {
        ret += tp.get("reserve_amount");
      });

      return ret;
    }),

    dealer_remittance: Ember.computed('instscheds.@each.dealer_remit_amount', 'pacfee', 'sponsor_comm', 'agent_comm', 'pass', 'total_tp_cost', 'inst_id.remit_tp_cost', function () {
      var pacfee = this.get('pacfee');
      var sponsor_comm = this.get('sponsor_comm');
      var agent_comm = this.get('agent_comm');
      var pass = this.get('pass');
      var instscheds = this.get("instscheds");
      var remit_tp_cost = this.get('inst_id.remit_tp_cost');
      var total_tp_cost = this.get('total_tp_cost');

      var ret = 0;

      ret += (pacfee + sponsor_comm + agent_comm + pass) / 100;

      instscheds.forEach(function (instsched) {
        ret += instsched.get("dealer_remit_amount");
      });

      ret += remit_tp_cost ? total_tp_cost : 0;

      return ret;
    }),

    formatted_dealer_remittance: Ember.computed('dealer_remittance', function () {
      return this.get('dealer_remittance').toFixed(2);
    }),

    pac_remittance: Ember.computed('instscheds.@each.pac_remit_amount', 'pacfee', 'sponsor_comm', 'agent_comm', 'pass', 'total_tp_cost', 'inst_id.{remit_tp_cost_pac,remit_sponsor_comm_pac,remit_agent_comm_pac,remit_passthru_pac}', function () {
      var pacfee = this.get('pacfee');

      var sponsor_comm = this.get('sponsor_comm');
      var remit_sponsor_comm_pac = this.get('inst_id.remit_sponsor_comm_pac');

      var agent_comm = this.get('agent_comm');
      var remit_agent_comm_pac = this.get('inst_id.remit_agent_comm_pac');

      var pass = this.get('pass');
      var remit_passthru_pac = this.get('inst_id.remit_passthru_pac');

      var instscheds = this.get("instscheds");
      var remit_tp_cost_pac = this.get('inst_id.remit_tp_cost_pac');
      var total_tp_cost = this.get('total_tp_cost');

      var ret = 0;

      ret += (pacfee + sponsor_comm * remit_sponsor_comm_pac + agent_comm * remit_agent_comm_pac + pass * remit_passthru_pac) / 100;

      instscheds.forEach(function (instsched) {
        ret += instsched.get("pac_remit_amount");
      });

      ret += remit_tp_cost_pac ? total_tp_cost : 0;

      return ret;
    }),

    formatted_pac_remittance: Ember.computed('pac_remittance', function () {
      return this.get('pac_remittance').toFixed(2);
    }),

    sale_price: Ember.computed('pacfee', 'sponsor_comm', 'agent_comm', 'pass', 'fi_comm', 'total_tp_cost', 'reserve_amount', function () {
      var pacfee = this.get('pacfee');
      var sponsor_comm = this.get('sponsor_comm');
      var agent_comm = this.get('agent_comm');
      var pass = this.get('pass');
      var fi_comm = this.get('fi_comm');

      var total_tp_cost = this.get('total_tp_cost');
      var reserve_amount = this.get('reserve_amount');

      var sale_price = pacfee + sponsor_comm + agent_comm + pass + fi_comm;
      sale_price = sale_price / 100;

      return sale_price + total_tp_cost + reserve_amount;
    }),

    formatted_sale_price: Ember.computed('sale_price', function () {
      return this.get('sale_price').toFixed(2);
    }),

    active_date_formatted: Ember.computed('active_date', function () {
      return moment(this.get('active_date')).format('MM/DD/YYYY');
    }),

    hasMaintPlan: Ember.computed('m', function () {
      return this.get('m') === "Y" ? true : false;
    }),

    hasLoyPlan: Ember.computed('l', function () {
      return this.get('l') === "Y" ? true : false;
    })
  });
});