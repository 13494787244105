define('cmp-dashboard/components/shared/layout/sidenav/nav-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    elementId: 'sidenav-toggle',
    actions: {
      toggleNav: function toggleNav() {
        this.get('toggleNav')();
      }
    }
  });
});