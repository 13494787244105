define('cmp-dashboard/models/schedt', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		sched_type: _emberData.default.attr('string'),
		language: _emberData.default.attr('string'),

		dealers_id: _emberData.default.belongsTo('dealer', { async: true })
	});
});