define('cmp-dashboard/components/shared/popup/popup-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    canClose: false,
    pageAlert: false,
    alertMessage: "You have unsaved changes on this form that will be discarded. Are you sure you want to close it?",

    // Inject Router
    //routing: inject('-routing'),

    /**
     * On page load, initiate button state
     */
    didInsertElement: function didInsertElement() {
      var self = this;

      Ember.$('.modal').modal();
      Ember.$('.modal').on('hide.bs.modal', function (e) {

        // If targeting outside the modal (background) run, otherwise cancel hide
        // This is a fix for the bootstrap datepicker triggering the hide
        if (Ember.$(e.target).hasClass('modal fade')) {

          // See if they have any unsaved data on this page.
          // If they do, show alert to notify them.
          if (self.get('application').get('unsaved') && !self.get('canClose')) {
            self.set('pageAlert', true);
            return false;
          }
        } else {
          return false;
        }
      });
      Ember.$('.modal').on('hidden.bs.modal', function () {

        var params = self.get('redirectParams');

        if (params) {
          self.get('parent').transitionToRoute(self.get('redirect'), params);
        } else {
          self.get('parent').transitionToRoute(self.get('redirect'));
        }
      });
    },

    actions: {
      onCancel: function onCancel() {
        this.set('pageAlert', false);
      },
      onConfirm: function onConfirm() {
        this.set('pageAlert', false);
        this.set('canClose', true);
        Ember.$('.modal').modal('hide');
      }
    }
  });
});