define('cmp-dashboard/components/shared/helpers/pdf-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),

    isLoading: true,
    hasError: false,
    loadingTemplate: '',
    error: '',

    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$('#pdfFrame').on('load', function () {
        Ember.run(function () {
          self.set('isLoading', false);
        });
        Ember.$('#pdfFrame').show().removeClass('hidden');
      });

      if (!this.get('params')) {
        this.set('params', {});
      }
    },


    fullUrl: Ember.computed('url', 'params', function () {
      return this.get('url') + '?' + this.get('params');
    })
  });
});