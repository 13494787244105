define('cmp-dashboard/adapters/application', ['exports', 'cmp-dashboard/config/environment', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _environment, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_NAMESPACE,
    authorize: function authorize(xhr) {
      var access_token = this.get('session.data.authenticated').token;
      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('X-Access-Token', '' + access_token);
      }
    },


    coalesceFindRequests: false,

    pathForType: function pathForType(type) {
      var path = this._super(type);

      if (['reply', 'comment'].indexOf(type) >= 0) {
        path = 'feedback/' + path;
      }

      if (['phonecall', 'reminder', 'contractcomment'].indexOf(type) >= 0) {
        path = 'contracts/' + localStorage.contract + '/' + path;
      }

      if (['feedback'].indexOf(type) >= 0) {
        path = 'feedback';
      }

      // If model name is not in array, add dealers/ before call
      if (['aclroletmplreport', 'agent', 'blockednumber', 'cfanswer', 'cfform', 'cfformsdescription', 'cfquestionoption', 'cfquestionoptionsdescription', 'contactpreference', 'dealer', 'inst', 'loyaltycategory', 'report', 'reportsclass', 'reportsname', 'rewardcategory', 'schedt', 'service', 'sponsor', 'text', 'user', 'userreportoverride'].indexOf(type) === -1) {
        path = 'dealers/' + localStorage.dealer + '/' + path;
      }
      if (['inst'].indexOf(type) >= 0) {
        path = 'sponsors/' + localStorage.sponsor + '/' + path;
      }

      var isAPI = true;
      var isIntegration = false;
      if (type.indexOf('search') === 0) {
        path = this._super(type.replace(/search/i, ''));
        isAPI = false;
      } else if (['lostopportunity'].indexOf(type) >= 0) {
        isAPI = false;
        isIntegration = true;
      }

      if (isAPI) {
        path = 'api/' + path;
      } else if (isIntegration) {
        path = 'integrations/frontend/' + path;
      } else {
        path = 'search/' + path;
      }

      if (['pendingcontract'].indexOf(type) >= 0) {
        path = 'integrations/frontend/dealers/' + localStorage.dealer + '/contracts/pending';
      }

      if (['lostopportunity'].indexOf(type) >= 0) {
        path = 'integrations/frontend/dealers/' + localStorage.dealer + '/lostopportunities';
      }

      if (['calldetailslist'].indexOf(type) >= 0) {
        path = 'integrations/frontend/dealers/' + localStorage.dealer + '/lostopportunities/calldetailslist';
      }

      if (['servicereminder'].indexOf(type) >= 0) {
        path = 'integrations/frontend/dealers/' + localStorage.dealer + '/servicereminders';
      }

      if (['reminderscalldetailslist'].indexOf(type) >= 0) {
        path = 'integrations/frontend/dealers/' + localStorage.dealer + '/servicereminders/calldetailslist';
      }

      if (['sponsorcontractregisterf'].indexOf(type) >= 0) {
        path = 'integrations/frontend/sponsors/' + localStorage.sponsor + '/contractregisterfs';
      }

      return path;
    },

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(url, "POST", { data: data[type.modelName] });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      if (snapshot.adapterOptions && snapshot.adapterOptions.access) {
        url += '?access=' + snapshot.adapterOptions.access;
      }

      return this.ajax(url, "PUT", { data: data[type.modelName] });
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors = payload ? payload.errors : null;

      if (status === 403 && errors) {
        var errorTitle = errors[0] ? errors[0].title : '';

        // If token is expired redirect to login
        // otherwise return error code
        if (errorTitle === 'TokenExpiredError') {
          status = 401;
        }
      }

      // if it is a sponsor contractregisterf 
      // we need to rename the response
      if (requestData.url.indexOf('sponsors') !== -1 && requestData.url.indexOf('contractregisterfs') !== -1) {
        if (payload.contractregisterfs) {
          payload.sponsorcontractregisterfs = payload.contractregisterfs;
          delete payload.contractregisterfs;
        } else if (payload.contractregisterf) {
          payload.sponsorcontractregisterf = payload.contractregisterf;
          delete payload.contractregisterf;
        }
      }

      return this._super(status, headers, payload, requestData);
    }
  });
});