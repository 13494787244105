define('cmp-dashboard/components/shared/helpers/time-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'time-picker',
    showHours: true,

    minutes: Ember.computed({
      get: function get() {
        return this.get('fullDate').getMinutes();
      },
      set: function set(key, value) {
        if (parseInt(value) === 'NaN') {
          value = new Date().getMinutes();
        }
        var current = this.get('fullDate');
        var date = new Date(current.getFullYear(), current.getMonth(), current.getDate(), current.getHours(), value, 0, 0);
        this.set('fullDate', date);
        return value;
      }
    }),

    display_minutes: Ember.computed('minutes', function () {
      var minutes = this.get('minutes').toString();

      if (minutes.length > 1) {
        return minutes;
      } else {
        return '0' + minutes;
      }
    }),

    hours: Ember.computed({
      get: function get() {
        return this.get('fullDate').getHours();
      },
      set: function set(key, value) {
        if (parseInt(value) === 'NaN') {
          value = new Date().getHours();
        }
        var current = this.get('fullDate');
        var date = new Date(current.getFullYear(), current.getMonth(), current.getDate(), value, current.getMinutes(), 0, 0);
        this.set('fullDate', date);
        return value;
      }
    }),

    display_hours: Ember.computed('hours', function () {
      var hours = parseInt(this.get('hours'));

      if (hours > 12) {
        return hours - 12;
      } else if (hours === 0) {
        return hours + 12;
      } else {
        return hours;
      }
    }),

    isAM: Ember.computed('hours', {
      get: function get() {
        return this.get('hours') < 12;
      },
      set: function set(key, value) {
        var hours = parseInt(this.get('hours'));
        if (hours <= 11 && !value) {
          this.set('hours', hours + 12);
        }
        if (hours > 11 && value) {
          this.set('hours', hours - 12);
        }
        return value;
      }
    }),

    actions: {
      setHours: function setHours(value) {
        var hours = parseInt(value);
        if (!this.get('isAM') && value <= 11) {
          hours += 12;
        } else if (this.get('isAM') && value > 11) {
          hours -= 12;
        }
        this.set('hours', hours);
        this.set('showHours', false);
      },
      setMinutes: function setMinutes(value) {
        this.set('minutes', parseInt(value));
        this.set('showHours', true);
      },
      showHours: function showHours() {
        this.set('showHours', true);
      },
      showMinutes: function showMinutes() {
        this.set('showHours', false);
      },
      isAM: function isAM(_isAM) {
        this.set('isAM', _isAM);
      }
    }
  });
});