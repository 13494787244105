define('cmp-dashboard/models/claiml', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    lcat: _emberData.default.attr('number'),
    loyalty_date: _emberData.default.attr('utc'),
    enter_date: _emberData.default.attr('utc'),
    ro_no: _emberData.default.attr('string'),
    lmileage: _emberData.default.attr('number'),
    lamt: _emberData.default.attr('number'),
    lpts: _emberData.default.attr('number'),
    lor: _emberData.default.attr('string'),
    lrem: _emberData.default.attr('string'),
    lupdate: _emberData.default.attr('string'),
    imported: _emberData.default.attr('number'),
    upsale: _emberData.default.attr('number'),
    historical: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    transfer_claim: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    s_dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    loyalty_category_id: _emberData.default.belongsTo('loyaltycategory', { async: true }),
    loyalty_options_id: _emberData.default.belongsTo('loyalty', { async: true }),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),

    formatted_loyalty_date: Ember.computed('loyalty_date', function () {
      return moment(this.get('loyalty_date')).format('MM/DD/YYYY');
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    formatted_amt: Ember.computed('lamt', function () {
      var amount = this.get('lamt');

      if (!amount) {
        amount = 0;
      }

      return '$' + amount.toFixed(2);
    }),

    formatted_upsale: Ember.computed('upsale', function () {
      var upsale = this.get('upsale');

      if (!upsale) {
        upsale = 0;
      }

      return '$' + upsale.toFixed(2);
    }),

    status_title: Ember.computed('dealers_id.name', 'formatted_enter_date', 'web_users_id.name', function () {
      var title = '<strong>Claimed at:</strong> ' + this.get('dealers_id.name') + '\n<strong>Enter Date:</strong> ' + this.get('formatted_enter_date') + '\n<strong>Entered by:</strong> ' + this.get('web_users_id.name');

      return title;
    })
  });
});