define('cmp-dashboard/controllers/dealers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    application: Ember.inject.service(),
    store: Ember.inject.service(),

    queryParams: ["page", "perPage", "name"],

    page: 1,
    perPage: 15,
    name: '',

    isLoading: true,

    actions: {
      select: function select(dealer) {

        var self = this;
        localStorage.dealer = dealer.id;

        if (localStorage.dealer && localStorage.dealer !== dealer.id) {
          this.get('application').set('previousRoute', null);
        }

        self.get('store').findRecord('dealer', dealer.id).then(function (dealer) {
          // Setup agent information
          sessionStorage.agent = dealer.get('agents_id.id');
          self.get('store').findRecord('agent', dealer.get('agents_id.id')).then(function (agent_obj) {
            localStorage.sponsor = agent_obj.get('sponsors_id.id');
          });

          // Set dealer info in application controller & service
          self.get('application').set('dealer', dealer);
          self.get('applicationController').set('dealer', dealer);

          if (typeof ga === 'function') {
            ga('set', 'dimension1', dealer.get('name'));
          }
          if (self.get('application.previousRoute')) {
            self.transitionToRoute(self.get('application.previousRoute'));
          } else {
            self.transitionToRoute('index');
          }
        });
      },
      pageClicked: function pageClicked(page) {
        this.setProperties({
          page: page
        });
      }
    }
  });
});