define('cmp-dashboard/components/shared/helpers/form/radio-button-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inline: true,
    classNames: 'radio-group'
  });
});