define('cmp-dashboard/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    question_type: _emberData.default.attr('string'),
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    groups_id: _emberData.default.attr('number'),
    active: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('date')
  });
});