define('cmp-dashboard/models/option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    option_type: _emberData.default.attr('string'),
    option_value: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('date'),
    active: _emberData.default.attr('number'),
    language: _emberData.default.attr('string'),

    web_users_id: _emberData.default.belongsTo('user'),
    dealers_id: _emberData.default.belongsTo('dealer')
  });
});