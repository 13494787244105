define('cmp-dashboard/components/shared/helpers/form/radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    change: function change() {
      this.set('groupValue', this.get('value'));

      if (this.get('onSelect')) {
        this.get('onSelect')();
      }
    },

    isValue: Ember.computed('groupValue', 'value', function () {
      return this.get('groupValue') === this.get('value');
    })
  });
});