define('cmp-dashboard/components/shared/helpers/tools/emoji-keyboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    emoji: Ember.inject.service(),

    classNames: 'emoji-keyboard',
    classNameBindings: ['isOpen:emoji-keyboard--active'],

    selectedCategory: 'people',

    actions: {
      changeCategory: function changeCategory(category) {
        this.set('selectedCategory', category);
      },
      insertEmoji: function insertEmoji(emoji) {
        this.get('insertEmoji')(emoji);
      },
      closeEmojiKeyboard: function closeEmojiKeyboard() {
        this.get('closeEmojiKeyboard')();
      }
    }
  });
});