define('cmp-dashboard/components/shared/tours/claims-contract', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var hasAvailableServices = Ember.$('#redeem-services').length > 0;
      var hasRedeemedServices = Ember.$('#redeemed-services').length > 0;
      var renewButton = this.get('application.dealer') ? this.get('application.dealer.post_billed_contracts') : false;
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              localStorage['claimsContractTourCount_' + user_id] = 100; // Make count high so it doesn't show again
              tour.cancel();
            }
          }],
          title: 'Claims Contract',
          text: ['Click through these steps to learn about processing claims and adjusting customer contract information.']
        }
      }, {
        id: 'customer-information',
        options: {
          attachTo: '#contract-header bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Customer Contract Information',
          text: ["Here you will find the customer's basic information. Make sure you are processing a claim for the right customer!"]
        }
      }, {
        id: 'edit-customer',
        options: {
          attachTo: '.card__title__edit right',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Edit Customer Information',
          text: ['Click this icon to open a window to edit the customer information. You cannot edit fields that are grayed out. Make sure to save when you are done editing!']
        }
      }, {
        id: 'contract-information',
        options: {
          attachTo: '.contract-details__customer__contact-info top',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Customer Contact Information',
          text: ["These 3 fields show a quick view of the customer's contact information. You can click all of them to edit the information as well."]
        }
      }, {
        id: 'layout-toggle',
        options: {
          attachTo: '.icon-toggle left',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Toggle Services Layout',
          text: ['Change between a table layout and a tile layout for the available services here. The tile layout is a bit easier to read, but takes up more screen space.']
        }
      }];

      if (hasAvailableServices) {
        steps.push({
          id: 'redeem-services',
          options: {
            attachTo: '#redeem-services top',
            beforeShowPromise: function beforeShowPromise() {
              return new Promise(function (resolve) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                  Ember.$('#page-content').scrollTop(Ember.$('#redeem-services').offset().top);
                  resolve();
                });
              });
            },
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Get Paid',
            text: ['If the contract is not expired, choose from the list of available services here that best suits the service you are providing. Enter the RO number, odometer, and total RO amount <b>including upsale before tax</b>.']
          }
        });
      }

      if (hasRedeemedServices) {
        steps.push({
          id: 'redeemed-services',
          options: {
            attachTo: '#redeemed-services top',
            beforeShowPromise: function beforeShowPromise() {
              return new Promise(function (resolve) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                  Ember.$('#page-content').scrollTop(Ember.$('#redeemed-services').offset().top);
                  resolve();
                });
              });
            },
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Edit Claims',
            text: ['You may edit the information of any claim with a <b>pending</b> status. After mid-month and month-end closing these claims status will be changed to <b>paid</b>. After this, you may only edit the claim date, RO number, or odometer.']
          }
        });
      }

      if (renewButton) {
        steps.splice(4, 0, {
          id: 'redeemed-services',
          options: {
            attachTo: '#renew-contract-button left',
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Renew Claims',
            text: ["Click the renew button if you would like to extend the customer's benefits after the current benefits have been paid or the contract has been expired."]
          }
        });
      }

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['claimsContractTourCount_' + user_id]) {
          localStorage['claimsContractTourCount_' + user_id]++;
        } else {
          localStorage['claimsContractTourCount_' + user_id] = 1;
        }
      });
    }
  });
});