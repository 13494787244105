define('cmp-dashboard/services/ajax', ['exports', 'ember-ajax/services/ajax', 'cmp-dashboard/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('trustedHosts', [_environment.default.APP.API_HOST]);
    },


    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session').get('data.authenticated.token');
        if (authToken) {
          headers['x-access-token'] = authToken;
        }
        headers['Content-Type'] = 'application/json; charset=utf-8';
        // headers['Access-Control-Allow-Origin'] = '*';
        // headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
        // headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT';

        return headers;
      }
    }),

    host: _environment.default.APP.API_HOST,
    namespace: ''
  });
});