define('cmp-dashboard/components/shared/layout/sidenav/user-profile', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    elementId: 'user-profile',

    tooltipLeftOpen: 256,
    tooltipLeftClosed: 68,

    didRender: function didRender() {
      if (window.innerWidth < 600) {
        this.set('tooltipBottom', 64);
      } else {
        this.set('tooltipBottom', 16);
      }
    },


    showUserMenu: false,
    userInitials: Ember.computed('application.user', function () {
      if (this.get('application.user')) {
        var words = this.get('application.user.name').split(" ");

        var initials = (words[0][0] + (words[1] ? words[1][0].match(/[a-zA-Z]/i) ? words[1][0] : '' : '')).toUpperCase();

        return initials;
      } else {
        return null;
      }
    }),

    tooltipLeft: Ember.computed('tooltipLeftOpen', 'tooltipLeftClosed', 'navOpen', function () {
      if (window.innerWidth < 600) {
        return '0.5rem';
      }

      if (this.get('navOpen')) {
        if (window.innerWidth < 1000) {
          return this.get('tooltipLeftClosed');
        }
        return this.get('tooltipLeftOpen');
      } else {
        return this.get('tooltipLeftClosed');
      }
    }),

    isVisible: Ember.computed('application.user', function () {
      return !Ember.isEmpty(this.get('application.user'));
    }),

    actions: {
      toggleUserMenu: function toggleUserMenu() {
        this.toggleProperty('showUserMenu');
      },
      changePassword: function changePassword() {
        this.get('changePassword')();
      },
      logout: function logout() {
        this.get('store').unloadAll();
        this.get('session').invalidate();
      },
      showNavTour: function showNavTour() {
        console.log('Show nav tour');
        this.get('showNavTour')();
      },
      showPagesTour: function showPagesTour() {
        // Don't run for Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
          return;
        }
        console.log('Show page tour');
        this.get('application').set('showPageTour', true);
      }
    }
  });
});