define('cmp-dashboard/components/shared/partials/icons/tile-svg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    attributeBindings: ['viewBox'],
    viewBox: '0 0 64 64'
  });
});