define('cmp-dashboard/models/reply', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    from: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),
    override: _emberData.default.attr(),
    guess: _emberData.default.attr(),
    needs_attention: _emberData.default.attr('boolean'),
    attention_fixed: _emberData.default.attr('boolean'),

    comments: _emberData.default.hasMany('comment', { async: true }),

    formatted_created: Ember.computed('created', function () {
      return moment(this.get('created')).format('MM/DD/YYYY');
    }),

    formatted_cell: Ember.computed('from', function () {
      var number = this.get('from');
      var formatted = number;

      if (number.indexOf('@') === -1) {
        formatted = '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
        return formatted;
      } else {
        return null;
      }
    }),

    score: Ember.computed('message', function () {
      if (parseInt(this.get('message'))) {
        return parseInt(this.get('message'));
      } else {
        return '';
      }
    }),

    isEmail: Ember.computed('from', function () {
      var from = this.get('from');
      var pattern = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

      return pattern.test(from);
    })
  });
});