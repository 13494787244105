define('cmp-dashboard/models/coupon', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dealer_sched_id: _emberData.default.belongsTo('sched', { async: true }),
    frq_options_id: _emberData.default.belongsTo('service', { async: true }),
    code: _emberData.default.attr('string'),
    cp_amount: _emberData.default.attr('number'),
    include_visit: _emberData.default.attr('string'),
    inst_id: _emberData.default.belongsTo('inst', { async: true }),
    percent: _emberData.default.attr('number'),
    priority: _emberData.default.attr('number'),
    historical: _emberData.default.attr('number'),

    formatted_amt: Ember.computed('cp_amount', function () {
      var price = this.get('cp_amount');

      if (!price) {
        price = 0;
      }

      price = price / 100;

      return '$' + price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    })
  });
});