define('cmp-dashboard/models/claimperegister', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global moment:true */
  var inflector = _emberInflector.default.inflector;

  inflector.irregular("claimperegister", "claimperegisters");

  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    register_date: _emberData.default.attr('utc'),
    claim_count: _emberData.default.attr('number'),
    total_claim_amount: _emberData.default.attr('number'),
    total_claim_upsale: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', {
      async: true
    }),
    inst_id: _emberData.default.belongsTo('inst', {
      async: true
    }),

    claim_pes: _emberData.default.hasMany('claimpe', {
      async: true
    }),

    formatted_register_date: Ember.computed('register_date', function () {
      return moment(this.get('register_date')).format('MM/DD/YYYY');
    }),

    formatted_status: Ember.computed('status', function () {
      var formatted = '';
      switch (this.get('status')) {
        case 'P':
        case 'p':
          formatted = 'Processed';
          break;
        case 'C':
        case 'c':
          formatted = 'Completed';
          break;
      }
      return formatted;
    }),

    formatted_total_claim_amount: Ember.computed('total_claim_amount', function () {
      return this.get('total_claim_amount').toFixed(2);
    })
  });
});