define('cmp-dashboard/components/shared/tours/claims-search', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.set('search', '');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.set('search', '');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.set('search', '');
        self.get('tourComplete')();
      });

      this.set('search', 'smith');

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var hasPendingContracts = this.get('pendingContracts');
      var hasPendingGoodwill = this.get('pendingGoodwill');
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              localStorage['claimsSearchTourCount_' + user_id] = 100; // Make count high so it doesn't show again
              tour.cancel();
            }
          }],
          title: 'Processing Claims',
          text: ['Click through these steps to learn about searching claims to be processed.']
        }
      }, {
        id: 'Search Claims',
        options: {
          attachTo: '#claims-search bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Search for Customer',
          text: ['Here you can search for the customer that you are creating a claim for. You can search by <b>First Name</b>, <b>Last Name</b>, <b>Email</b>, or <b>VIN8</b>.']
        }
      }, {
        id: 'missing-customer',
        options: {
          attachTo: '#create-contract bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Customer Not Found',
          text: ['If the customer is not in the list and would like to purchase a service drive maintenance plan, click here.']
        }
      }, {
        id: 'search-results',
        options: {
          attachTo: {
            element: '#claims-page .table',
            on: 'top'
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Search Results',
          text: ['All contracts that match your search parameters are shown here. Make sure you choose the correct customer by checking the <b>VIN8</b> with your customer VIN number.']
        }
      }, {
        id: 'pending-claims',
        options: {
          attachTo: '#pending-claims-button bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Pending Claims',
          text: ['Click here to view a list of the pending claims for your dealership. Pending claims will be paid at mid-month and month-end.']
        }
      }];

      if (hasPendingGoodwill) {
        steps.push({
          id: 'pending-goodwill',
          options: {
            attachTo: '#pending-goodwill-claims-button bottom',
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Pending Goodwill Claims',
            text: ['Click here to view a list of the pending goodwill claims for your dealership.']
          }
        });
      }

      if (hasPendingContracts) {
        steps.push({
          id: 'pending-contracts',
          options: {
            attachTo: '#pending-contracts-button bottom',
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Pending Contracts',
            text: ['Click here to view a list of the pending service drive contracts for your dealership.']
          }
        });
      }

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['claimsSearchTourCount_' + user_id]) {
          localStorage['claimsSearchTourCount_' + user_id]++;
        } else {
          localStorage['claimsSearchTourCount_' + user_id] = 1;
        }
      });
    }
  });
});