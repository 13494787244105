define('cmp-dashboard/components/shared/helpers/form/contract/customer-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'form-section',

    didInsertElement: function didInsertElement() {
      this.set('tel', this.get('model.formatted_tel'));
      this.set('mobile_number', this.get('model.formatted_mobile_number'));
    },


    actions: {
      setTelValues: function setTelValues(blur) {
        if (blur) {
          var contract = this.get('model');
          var tel = this.get('tel');

          contract.set('tel', tel);
          this.set('tel', contract.get('formatted_tel'));
        } else {
          var _contract = this.get('model');

          this.set('tel', _contract.get('tel'));
        }
      },
      setCellValues: function setCellValues(blur) {
        if (blur) {
          var contract = this.get('model');
          var mobile_number = this.get('mobile_number');

          contract.set('mobile_number', mobile_number);
          this.set('mobile_number', contract.get('formatted_mobile_number'));
        } else {
          var _contract2 = this.get('model');

          this.set('mobile_number', _contract2.get('mobile_number'));
        }
      }
    }
  });
});