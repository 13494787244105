define('cmp-dashboard/models/claimr', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    rcat: _emberData.default.attr('number'),
    reward_date: _emberData.default.attr('utc'),
    enter_date: _emberData.default.attr('utc'),
    ro_no: _emberData.default.attr('string'),
    rmileage: _emberData.default.attr('number'),
    ramt: _emberData.default.attr('number'),
    rpts: _emberData.default.attr('number'),
    ror: _emberData.default.attr('string'),
    rrem: _emberData.default.attr('string'),
    rupdate: _emberData.default.attr('string'),
    upsale: _emberData.default.attr('number'),
    historical: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    transfer_claim: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    s_dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    reward_category_id: _emberData.default.belongsTo('rewardcategory', { async: true }),
    reward_options_id: _emberData.default.belongsTo('reward', { async: true }),
    upsale_claim_l_id: _emberData.default.belongsTo('claiml', { async: true }),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),

    reward_amount_used: Ember.computed('dealers_id.redeem_rate', 'rpts', function () {
      var rpts = this.get('rpts');
      var rate = this.get('dealers_id.redeem_rate');

      return (rpts * rate / 100).toFixed(2);
    }),

    formatted_reward_date: Ember.computed('reward_date', function () {
      return moment(this.get('reward_date')).format('MM/DD/YYYY');
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    formatted_amt: Ember.computed('ramt', function () {
      var amount = this.get('ramt');

      if (!amount) {
        amount = 0;
      }

      return '$' + amount.toFixed(2);
    }),

    formatted_upsale: Ember.computed('upsale', function () {
      var upsale = this.get('upsale');

      if (!upsale) {
        upsale = 0;
      }

      return '$' + upsale.toFixed(2);
    }),

    status_title: Ember.computed('dealers_id.name', 'formatted_enter_date', 'web_users_id.name', function () {
      var title = '<strong>Claimed at:</strong> ' + this.get('dealers_id.name') + '\n<strong>Enter Date:</strong> ' + this.get('formatted_enter_date') + '\n<strong>Entered by:</strong> ' + this.get('web_users_id.name');

      return title;
    })
  });
});