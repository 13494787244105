define('cmp-dashboard/routes/login', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-cli-pagination/remote/route-mixin'], function (exports, _authenticatedRouteMixin, _routeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeMixin.default, {
        session: Ember.inject.service(),

        beforeModel: function beforeModel() {
            window.location.replace("/login");
        },
        model: function model() {
            return null;
        }
    });
});