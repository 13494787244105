define('cmp-dashboard/app', ['exports', 'ember-resolver', 'ember-load-initializers', 'cmp-dashboard/config/environment', '@sentry/browser'], function (exports, _emberResolver, _emberLoadInitializers, _environment, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  if (_environment.default.environment === 'production' || _environment.default.environment === 'development') {
    _browser.init({
      dsn: 'https://ce20fd6f152e42c5ac30bfca4375e8f8@sentry.io/1376129',
      integrations: [new _browser.Integrations.Ember()],
      environment: _environment.default.environment,
      ignoreErrors: ['TransitionAborted', '401', '403'],
      beforeSend: function beforeSend(event) {
        return event && event.message && event.message.match(/SentryError: HTTP Error \(429\)/) ? null : event;
      }
    });
  }

  var App = void 0;

  // force https on prod
  if (window.location.href.match('http://new.')) {
    window.location.href = window.location.href.replace('http', 'https');
  }

  App = Ember.Application.extend({
    rootElement: '#app',
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});