define('cmp-dashboard/models/agent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    tel_ex: _emberData.default.attr('string'),
    cell: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    p_fname: _emberData.default.attr('string'),
    p_lname: _emberData.default.attr('string'),
    p_email: _emberData.default.attr('string'),
    c_fname: _emberData.default.attr('string'),
    c_lname: _emberData.default.attr('string'),
    c_email: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('date'),
    fed_id: _emberData.default.attr('string'),
    ssn: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    agent_commission_payment_method: _emberData.default.attr('string'),
    reports_email: _emberData.default.attr('string'),
    hide_welcome_logo: _emberData.default.attr('number'),
    logo_interlaced: _emberData.default.attr('number'),

    sponsors_id: _emberData.default.belongsTo('sponsor', { async: true }),

    fullAddress: Ember.computed('address1', 'address2', 'city', 'state', 'zip', 'zip2', function () {
      var address = this.get('address1');

      if (this.get('address2')) {
        address += ' ' + this.get('address2');
      }

      address += '\n' + this.get('city') + ', ' + this.get('state') + ' ' + this.get('zip');

      if (this.get('zip2')) {
        address += '-' + this.get('zip2');
      }

      return address;
    }),

    formatted_tel: Ember.computed('tel', function () {
      if (this.get('tel') && this.get('tel') !== '') {
        return this.get('tel').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      // No telephone
      return null;
    }),

    formatted_cell: Ember.computed('cell', function () {
      if (this.get('cell') && this.get('cell') !== '') {
        return this.get('cell').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      // No telephone
      return null;
    })
  });
});