define('cmp-dashboard/models/pendingcontract', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contractf_id: _emberData.default.attr('number'),
    sched_id: _emberData.default.attr('number'),
    fname: _emberData.default.attr('string'),
    lname: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    mobile_number: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    car_year: _emberData.default.attr('string'),
    car_make: _emberData.default.attr('string'),
    car_model: _emberData.default.attr('string'),
    car_used: _emberData.default.attr('string'),
    vin: _emberData.default.attr('string'),
    vin8: _emberData.default.attr('string'),
    odo: _emberData.default.attr('number'),
    salesman_id: _emberData.default.attr('string'),
    purchase_date: _emberData.default.attr('utc'),
    customer_price: _emberData.default.attr('number'),
    dms_customer_number: _emberData.default.attr('number'),
    reserve_amount: _emberData.default.attr('number'),
    dealer_remit_amount: _emberData.default.attr('number'),
    pac_remit_amount: _emberData.default.attr('number'),

    formatted_purchase_date: Ember.computed('purchase_date', function () {
      var date = this.get('purchase_date');
      return moment(date).format('MM/DD/YYYY');
    }),

    formatted_new_used: Ember.computed('car_used', function () {
      if (this.get('car_used') === 'Y') {
        return 'Used';
      } else {
        return 'New';
      }
    })
  });
});