define('cmp-dashboard/models/comment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    comment: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),

    formatted_created: Ember.computed('created', function () {
      return moment(this.get('created')).format('MM/DD/YYYY');
    })
  });
});