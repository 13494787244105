define('cmp-dashboard/services/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    dealer: '',
    user: '',
    unsaved: false
  });
});