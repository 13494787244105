define('cmp-dashboard/components/shared/helpers/tools/list-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "section",
    classNames: "pagination-container",

    page: 1,
    paginateBy: 10,

    shownFromCurrent: 3,

    // Returns the list of items for the current page only
    paginatedItems: Ember.computed('items', 'page', function () {
      var itemsStart = (parseInt(this.get('page')) - 1) * parseInt(this.get('paginateBy'));
      var itemsEnd = itemsStart + parseInt(this.get('paginateBy'));
      return this.get('items').slice(itemsStart, itemsEnd);
    }),

    // The total number of pages that our items span
    numberOfPages: Ember.computed('page', 'items', function () {
      var length = this.get('items.length');
      var perPage = parseInt(this.get('paginateBy'));
      var totalPages = Math.floor(length / perPage);
      if (length % perPage > 0) {
        totalPages += 1;
      }

      return totalPages;
    }),

    // An array containing the number of each page: [1, 2, 3, 4, 5, ...]
    pageNumbers: Ember.computed('numberOfPages', function () {
      var totalPages = Array(this.get('numberOfPages'));
      for (var i = 0; i < totalPages.length; i++) {
        totalPages[i] = i + 1;
      }
      return totalPages;
    }),

    // Min pages away from current page
    // If 3 and page is 4, only show pages down to 1: [1, 2, 3, 4]
    minShownPage: Ember.computed('page', 'shownFromCurrent', function () {
      var page = this.get('page');
      var shownFromCurrent = this.get('shownFromCurrent');

      var min = page - shownFromCurrent;

      if (min < 1) {
        min = 1;
      }

      return min;
    }),

    // Max pages away from current page
    // If 3 and page is 1, only show pages out to 4: [1, 2, 3, 4]
    maxShownPage: Ember.computed('page', 'numberOfPages', 'shownFromCurrent', function () {
      var page = this.get('page');
      var numberOfPages = this.get('numberOfPages');
      var shownFromCurrent = this.get('shownFromCurrent');

      var max = page + shownFromCurrent;

      if (max > numberOfPages) {
        max = numberOfPages;
      }

      return max;
    }),

    // Whether or not to show the "next" button
    showNext: Ember.computed('page', 'numberOfPages', function () {
      return this.get('page') < this.get('numberOfPages');
    }),

    // Whether or not to show the "previous" button
    showPrevious: Ember.computed('page', 'numberOfPages', function () {
      return this.get('page') > 1;
    }),

    actions: {
      // Show the next page of items
      nextClicked: function nextClicked() {
        if (this.get('page') + 1 <= this.get('numberOfPages')) {
          this.set('page', this.get('page') + 1);
        }
      },

      // Show the previous page of items
      previousClicked: function previousClicked() {
        if (this.get('page') > 0) {
          this.set('page', this.get('page') - 1);
        }
      },

      // Go to the clicked page of items
      pageClicked: function pageClicked(pageNumber) {
        this.set('page', pageNumber);
      }
    }
  });
});