define('cmp-dashboard/components/shared/helpers/feedback-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    elementId: 'feedback-container',
    open: false,
    feedbackMessage: '',
    account_rep: '',

    didInsertElement: function didInsertElement() {
      Ember.$('#feedback-container').attr('data-html2canvas-ignore', true);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.getAccountRep();
    },
    getAccountRep: function getAccountRep() {
      var rep_id = this.get('dealer.account_rep');

      if (!Ember.isEmpty(rep_id)) {
        this.set('account_rep', rep_id);
      }
    },
    getBrowser: function getBrowser() {
      // Opera 8.0+
      var isOpera = !!window.opr && !!window.opr.addons || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';

      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      }(!window['safari'] || typeof window.safari !== 'undefined' && window.safari.pushNotification);

      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;

      // Chrome 1 - 71
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;

      if (isOpera) {
        return 'Opera';
      }

      if (isFirefox) {
        return 'Firefox';
      }

      if (isSafari) {
        return 'Safari';
      }

      if (isIE) {
        return 'Internet Explorer';
      }

      if (isEdge) {
        return 'Edge';
      }

      if (isChrome) {
        return 'Chrome';
      }

      if (isBlink) {
        return 'Blink';
      }
    },


    actions: {
      sendFeedback: function sendFeedback() {
        var self = this;

        if (this.get('feedbackMessage').length === 0) {
          this.set('errors', [{
            message: 'Please enter a description of the issue you are having.'
          }]);
          return;
        }

        var app = document.getElementById('app');

        app.style.scrollY = 'hidden';

        html2canvas(app).then(function (canvas) {
          app.style.scrollY = 'auto';

          var screenshot = canvas.toDataURL('image/jpeg', 0.3);
          var browser = self.getBrowser();
          var OS = window.navigator.platform;
          // const error = sessionStorage.lastError;
          var URL = window.location.href;
          var email = localStorage.email ? localStorage.email : '';
          var name = self.get('user') ? self.get('user') : '';
          var dealer_id = self.get('dealer.id') ? self.get('dealer.id') : '';
          var dealer_name = self.get('dealer.name') ? self.get('dealer.name') : '';
          var dealer_tel = self.get('dealer.tel') ? self.get('dealer.tel') : '';
          var account_rep = self.get('account_rep');

          var data = {
            message: self.get('feedbackMessage'),
            browser: browser,
            OS: OS,
            URL: URL,
            dealer_id: dealer_id,
            dealer_name: dealer_name,
            dealer_tel: dealer_tel,
            account_rep: account_rep,
            email: email,
            name: name,
            image: screenshot,
            developerDetails: {
              localStorage: JSON.stringify(localStorage),
              sessionStorage: JSON.stringify(sessionStorage)
            }
          };

          return self.get('ajax').request('/api/feedback', {
            method: 'POST',
            data: JSON.stringify(data)
          }).then(function (response) {
            if (!response.error_message) {
              self.set('open', false);
              self.get('toast').success('Thank you for your feedback. We will work on fixing the issue.');
              self.set('feedbackMessage', '');
              self.set('errors', []);
            } else {
              self.set('errors', response.error_message);
              self.get('toast').error('There was a problem sending the email! Please try again later or contact support@performanceadmin.com');
            }
          }).catch(function (errors) {
            self.set('errors', [errors]);
            self.get('toast').error('There was a problem sending the email! Please try again later or contact support@performanceadmin.com');
          });
        });
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('open');
        this.set('feedbackMessage', '');
      },
      closeModal: function closeModal() {
        this.set('open', false);
      }
    }
  });
});