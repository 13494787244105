define('cmp-dashboard/components/shared/helpers/form/make-model-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'inline-form inline-form--wrap-md make-model-filter',

    carMakes: Ember.computed('contracts', function () {
      var contracts = this.get('contracts');
      var carMakes = [];

      if (contracts) {
        contracts.forEach(function (contract) {
          var make = contract.get('car_make');

          if (carMakes.indexOf(make) === -1) {
            carMakes.push(make);
          }
        });

        return carMakes.sort();
      } else {
        return null;
      }
    }),

    carModels: Ember.computed('contracts', 'selectedMake', function () {
      var contracts = this.get('contracts');
      var selectedMake = this.get('selectedMake');
      var carModels = [];

      if (selectedMake === '') {
        return [];
      }

      contracts.forEach(function (contract) {
        var make = contract.get('car_make');
        var model = contract.get('car_model');

        if (make === selectedMake && carModels.indexOf(model) === -1) {
          carModels.push(model);
        }
      });

      return carModels.sort();
    }),

    actions: {
      updateMake: function updateMake(e) {
        this.set('selectedMake', e.target.value);
        this.set('selectedModel', '');
      }
    }
  });
});