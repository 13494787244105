define('cmp-dashboard/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),

    /*
     * TODO: the dates need to be actual dates
     */
    active: _emberData.default.attr('boolean'),
    /*active_date: DS.attr('date'),*/
    active_date: _emberData.default.attr('string'),
    force_pwd_change: _emberData.default.attr('boolean'),
    groups_id: _emberData.default.attr('number'),
    landing_page: _emberData.default.attr('string'),
    last_browser: _emberData.default.attr('string'),
    /*last_login: DS.attr('date'),*/
    last_login: _emberData.default.attr('string'),
    last_message: _emberData.default.attr('number'),
    locale: _emberData.default.attr('string'),
    login_count: _emberData.default.attr('number'),
    login_ip: _emberData.default.attr('string'),
    login_site: _emberData.default.attr('string'),
    receive_exec_report: _emberData.default.attr('boolean'),
    reps_id: _emberData.default.attr('number'),
    role: _emberData.default.attr('string'),
    pwd: _emberData.default.attr('string'),
    password_reset_hash: _emberData.default.attr('string'),

    beta_access_office: _emberData.default.attr('boolean'),
    beta_access_service: _emberData.default.attr('boolean'),
    cxt_reports_access: _emberData.default.attr('boolean'),

    agents_id: _emberData.default.belongsTo('agent', { async: true }),
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    sponsors_id: _emberData.default.belongsTo('sponsor', { async: true }),

    permissions_user: _emberData.default.attr(),

    isAdmin: Ember.computed.equal('role', 'admin'),

    firstName: Ember.computed('name', function () {
      var fullName = this.get('name');

      return fullName.substring(0, fullName.indexOf(' '));
    })
  });
});