define('cmp-dashboard/models/cfquestionoption', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    cf_questions_id: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    groups_id: _emberData.default.attr('number'),
    active: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('date'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    descriptions: _emberData.default.hasMany('cfquestionoptionsdescription', { async: true }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    })
  });
});