define('cmp-dashboard/components/dashboard/layouts/office-layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    tagName: 'section',
    elementId: 'dashboard-cards',
    classNames: 'cards',

    active_contracts_loading: false,
    pending_contracts_loading: false,
    pending_claims_loading: false,
    visit_retention_loading: false,
    last_claim_payment_loading: false,

    // Link URLs
    active_contracts_link: '/office/#/registers/contracts',
    pending_contracts_link: '/office/#/contracts',
    pending_claims_link: '/office/#/claims',
    visit_retention_link: '/reports/#/reports/contract_reserve',
    last_claim_payment_link: '/office/#/registers/claims',

    didReceiveAttrs: function didReceiveAttrs() {
      // Set default values for the cards
      this.set('active_contracts_data', []);
      this.set('pending_contracts_data', []);
      this.set('pending_claims_data', []);
      this.set('visit_retention_data', []);
      this.set('last_claim_payment_data', []);

      this.setCardValues();
    },
    setCardValues: function setCardValues() {
      this.setActiveContracts();
      this.setPendingContracts();
      this.setPendingClaims();
      this.setVisitRetention();
      this.setLastClaimPayment();
    },


    // get data for dealer's active contracts
    setActiveContracts: function setActiveContracts() {
      var self = this;

      this.set('active_contracts_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/executive_report/total_contracts').then(function (total_contracts) {

        var active_contracts = [];
        active_contracts.push({
          value: total_contracts.data[0][1],
          label: 'Active Contracts',
          sublabel: 'Current'
        });

        if (!self.get('isDestroyed')) {
          self.set('active_contracts_loading', false);
          self.set('active_contracts_data', active_contracts);
        }
      });
    },


    // get data for pending contracts
    setPendingContracts: function setPendingContracts() {
      var self = this;

      this.set('pending_contracts_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/pending_contracts').then(function (total_pending_contracts) {

        var pending_contracts = [];
        pending_contracts.push({
          value: total_pending_contracts.pending_contracts,
          label: 'Pending Contracts',
          sublabel: 'Current'
        });

        if (!self.get('isDestroyed')) {
          self.set('pending_contracts_loading', false);
          self.set('pending_contracts_data', pending_contracts);
        }
      });
    },


    // get data for pending claims
    setPendingClaims: function setPendingClaims() {
      var self = this;

      this.set('pending_claims_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/pending_claims').then(function (total_pending_claims) {

        var pending_claims = [];
        pending_claims.push({
          value: total_pending_claims.pending_claims,
          label: 'Pending Claims',
          sublabel: 'Current'
        });

        if (!self.get('isDestroyed')) {
          self.set('pending_claims_loading', false);
          self.set('pending_claims_data', pending_claims);
        }
      });
    },


    // get data for pending claims
    setVisitRetention: function setVisitRetention() {
      var self = this;

      this.set('visit_retention_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/visit_retention').then(function (visit_retention) {
        var visit_retention_percentage = parseFloat(visit_retention.meta.visit_retention * 100);
        var visit_retention_data = [];
        visit_retention_data.push({
          value: visit_retention_percentage ? visit_retention_percentage.toFixed(0) + '%' : '0%',
          label: 'Visit Retention',
          sublabel: 'ITD'
        });

        if (!self.get('isDestroyed')) {
          self.set('visit_retention_loading', false);
          self.set('visit_retention_data', visit_retention_data);
        }
      });
    },


    // get data for pending claims
    setLastClaimPayment: function setLastClaimPayment() {
      var self = this;

      this.set('last_claim_payment_loading', true);
      this.get('ajax').request('/api/dealers/' + localStorage.dealer + '/claimregisters?sort=enter_date&dir=desc').then(function (claim_registers) {

        var recent_claim_register = claim_registers.claimregisters[0];

        var last_claim_payment_data = [];
        last_claim_payment_data.push({
          value: recent_claim_register.count,
          label: 'Total Claims',
          sublabel: 'Last Claim Register'
        });
        last_claim_payment_data.push({
          value: '$' + recent_claim_register.amt,
          label: 'Total Amount',
          sublabel: 'Last Claim Register'
        });

        if (!self.get('isDestroyed')) {
          self.set('last_claim_payment_loading', false);
          self.set('last_claim_payment_data', last_claim_payment_data);
        }
      });
    }
  });
});