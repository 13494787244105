define('cmp-dashboard/components/shared/partials/dealers/dealer-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: 'table__row',
    classNameBindings: ['dealerClassName'],

    dealerClassName: Ember.computed('item.id', function () {
      return 'did-' + this.get('item').get('id');
    }),

    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        this.send('select');
      }
    },

    click: function click() {
      this.send('select');
    },

    actions: {
      select: function select() {
        this.get('selectDealer')(this.get('item'));
      }
    }
  });
});