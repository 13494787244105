define('cmp-dashboard/components/shared/helpers/form/date-field', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: 'date',

    monthID: Ember.computed('id', function () {
      return this.get('id') + '_month';
    }),
    dayID: Ember.computed('id', function () {
      return this.get('id') + '_day';
    }),
    yearID: Ember.computed('id', function () {
      return this.get('id') + '_year';
    }),
    month: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(this.get('date')).format('MM');
      } else {
        return '';
      }
    }),
    day: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(this.get('date')).format('DD');
      } else {
        return '';
      }
    }),
    year: Ember.computed('date', function () {
      if (this.get('date')) {
        return (0, _moment.default)(this.get('date')).format('YYYY');
      } else {
        return '';
      }
    }),
    clear: Ember.observer('clearDate', function () {
      if (this.get('clearDate')) {
        this.set('month', '');
        this.set('day', '');
        this.set('year', '');
        this.get('dateCleared')();
      }
    }),
    onDateChange: Ember.observer('month', 'day', 'year', function () {
      var month = this.get('month');
      var day = this.get('day');
      var year = this.get('year');

      if (month.length === 2 && day.length === 2 && year.length === 4) {
        var newDate = (0, _moment.default)(this.get('year') + '-' + this.get('month') + '-' + this.get('day'));

        if (newDate.isValid()) {
          this.set('date', newDate.toDate());
        }
      }
    }),

    actions: {
      endEdits: function endEdits() {
        var day = parseInt(this.get('day'));
        var month = parseInt(this.get('month'));
        var year = parseInt(this.get('year'));

        var string_day = (0, _moment.default)().format("DD");
        var string_month = (0, _moment.default)().format("MM");
        var string_year = (0, _moment.default)().format("YYYY");

        if (day > 0 && day < 10) {
          string_day = "0" + day;
        } else if (day >= 10) {
          string_day = day;
        }

        if (month > 0 && month < 10) {
          string_month = "0" + month;
        } else if (month >= 10) {
          string_month = month;
        }

        if (year > 0 && year < 10) {
          string_year = "200" + year;
        } else if (year >= 10 && year < 100) {
          string_year = "20" + year;
        } else {
          string_year = year;
        }

        this.set('day', string_day);
        this.set('month', string_month);
        this.set('year', string_year);

        var new_date = (0, _moment.default)(string_year + '-' + string_month + '-' + string_day);
        this.set('date', new_date.toDate());
      }
    }
  });
});