define('cmp-dashboard/components/shared/layout/sidenav-section', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    tour: Ember.inject.service(),

    tagName: 'aside',
    elementId: 'sidenav',

    classNameBindings: ['open:sidenav--open'],
    open: true,
    isMobileNav: false,

    didInsertElement: function didInsertElement() {
      var self = this;
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';

      if (this.get('application.showNavTour') && !localStorage['disableNavTour_' + user_id]) {
        this.set('showNavTour', true);
        this.set('notifyWhenComplete', true);
      }

      if (localStorage.navOpen === 'false') {
        this.set('open', false);
      }

      Ember.$(window).on('resize', function () {
        var width = window.innerWidth;
        self.set('isMobileNav', width < 1000);
      });

      this.set('env', _environment.default.environment);
    },


    shouldStartTour: Ember.observer('application.showNavTour', function () {
      var user_id = this.get('application.user.id').substring(0, 7);

      if (!this.get('application.showNavTour') || localStorage['disableNavTour_' + user_id]) {
        return;
      }

      // Don't run for Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;
      if (isIE) {
        return;
      }

      this.set('showNavTour', true);
      this.set('notifyWhenComplete', true);
    }),

    actions: {
      closeNavTour: function closeNavTour() {
        console.log('Close nav tour');
        if (this.get('notifyWhenComplete')) {
          this.get('navTourComplete')();
        }
        this.set('showNavTour', false);
      },
      toggleNav: function toggleNav() {
        this.toggleProperty('open');

        localStorage.navOpen = this.get('open');
      },
      showNavTour: function showNavTour() {

        // Don't run for Internet Explorer 6-11
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        if (isIE) {
          return;
        }

        this.set('showNavTour', true);
        this.set('notifyWhenComplete', false);
      }
    }
  });
});