define('cmp-dashboard/components/shared/layout/header-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),

    tagName: 'header',
    elementId: 'header',

    dealerName: Ember.computed('application.dealer.name', function () {
      if (this.get('application.dealer')) {
        return this.get('application.dealer.name');
      } else {
        return '';
      }
    })
  });
});