define('cmp-dashboard/components/dashboard/dashboard-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'card card--info card--dash',

    active_index: 0,
    height: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      var card_data = this.get('card_data');

      if (card_data) {

        // Reset active to 0 when new data arrives
        self.set('active_index', 0);

        // If cards have more than one data value increase the index every 7 seconds (+800ms for transition animation)
        if (card_data.length > 1) {
          if (this.get('interval')) {
            window.clearInterval(this.get('interval'));
          }

          var interval = window.setInterval(function () {
            self.increment();
          }, 7800);
          this.set('interval', interval);
        }
      }
    },


    // show navigation if card_data has more than one set of data
    showNavigation: Ember.computed('card_data.length', function () {
      return this.get('card_data.length') > 1;
    }),

    // increment active index
    increment: function increment() {
      var card_data = this.get('card_data');

      var active_index = this.get('active_index');
      if (card_data) {
        // don't allow active index above card_data length
        if (active_index === card_data.length - 1) {
          active_index = 0;
        } else {
          active_index++;
        }
      }

      this.set('active_index', active_index);
    },

    willDestroyElement: function willDestroyElement() {
      var interval = this.get('interval');
      clearInterval(interval);
    },


    actions: {
      updateActive: function updateActive(active_index) {
        var self = this;

        // Set active index value
        this.set('active_index', active_index);

        // Reset interval
        window.clearInterval(this.get('interval'));

        var interval = window.setInterval(function () {
          self.increment();
        }, 4800);
        this.set('interval', interval);
      },
      setHeight: function setHeight(height) {
        if (this.get('height') < height) {
          this.set('height', height);
          this.$().find('.card__slider').css('height', height);
        }
      }
    }
  });
});