define('cmp-dashboard/components/shared/tours/contracts-processing', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              tour.cancel();
            }
          }],
          title: 'Processing Contracts',
          text: ['Click through these steps to learn about processing contracts']
        }
      }, {
        id: 'add-contract',
        options: {
          attachTo: '#add-contract bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Add Contract',
          text: ['Click here to add a new contract. Fill in the required fields and click save to add the contract to the list of contracts ready to be processed.']
        }
      }, {
        id: 'search-contracts',
        options: {
          attachTo: '.searchbar bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Search Contracts',
          text: ['To find contracts easier, enter the customer name or vin here to filter the list.']
        }
      }, {
        id: 'contracts-table-header',
        options: {
          attachTo: '.table__row--header top',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Sort Contracts',
          text: ['The table header is used for sorting the contracts by different fields. By default, contracts are sorted by purchase date. You can also click the checkbox to select/deselect all contracts in the list.']
        }
      }];

      if (this.get('contracts.length') > 0) {
        steps.push({
          id: 'contract',
          options: {
            attachTo: '.table__row:nth-child(2) top',
            beforeShowPromise: function beforeShowPromise() {
              return new Promise(function (resolve) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                  Ember.$('#page-content').scrollTop(0);
                  resolve();
                });
              });
            },
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Contract Information',
            text: ['Here you will find all of the information for a contract. Click on the row to expand it out and show more information. You can select a term if the contract does not already have one. <b>You can now right-click on the contract to edit/delete the contract.</b>']
          }
        });
      }

      if (this.get('loadMore')) {
        steps.push({
          id: 'load-more',
          options: {
            attachTo: '#load-more-contracts top',
            beforeShowPromise: function beforeShowPromise() {
              return new Promise(function (resolve) {
                Ember.run.scheduleOnce('afterRender', this, function () {
                  Ember.$('#page-content').scrollTop(Ember.$('#contracts-index-page').height());
                  resolve();
                });
              });
            },
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Load More',
            text: ['For performance reasons, only 50 contracts are loaded at a time. Click here to load more contracts.']
          }
        });
      }

      steps.push({
        id: 'delete-multiple',
        options: {
          attachTo: {
            element: '#contracts-delete > .btn',
            on: 'top'
          },
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(Ember.$('#contracts-index-page').height());
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Delete Multiple Contracts',
          text: ['If you have multiple contracts needing to be deleted, click here to delete all of the contracts with the right checkbox marked.']
        }
      });

      if (this.get('application.dealer.ach_confirmed')) {
        steps.push({
          id: 'check-number',
          options: {
            attachTo: '#check_number top',
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Check Number',
            text: ['Once you have selected contracts to be processed, enter the check number or ACH here.']
          }
        });
      } else {
        steps.push({
          id: 'check-number',
          options: {
            attachTo: '#check_number top',
            buttons: [{
              classes: 'btn btn--default',
              text: 'Back',
              type: 'back'
            }, {
              classes: 'btn btn--danger',
              text: 'Exit',
              type: 'cancel'
            }, {
              classes: 'btn btn--default',
              text: 'Next',
              type: 'next'
            }],
            title: 'Check Number',
            text: ['Once you have selected contracts to be processed, enter the check number here.']
          }
        });
      }

      steps.push({
        id: 'submit',
        options: {
          attachTo: 'button[type=submit] top',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }],
          title: 'Submit Payment',
          text: ['Once you have selected contracts and entered the check number for the payment, click here to start processing the new contracts.']
        }
      });

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['contractsTourCount_' + user_id]) {
          localStorage['contractsTourCount_' + user_id]++;
        } else {
          localStorage['contractsTourCount_' + user_id] = 1;
        }
      });
    }
  });
});