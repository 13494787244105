define('cmp-dashboard/components/shared/helpers/tools/print-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    toast: Ember.inject.service(),

    tagName: 'button',
    classNames: 'btn btn--white',

    click: function click() {
      window.print();
    }
  });
});