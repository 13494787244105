define('cmp-dashboard/models/contractupdate', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    object: _emberData.default.attr('string'),
    object_id: _emberData.default.attr('number'),
    before: _emberData.default.attr('string'),
    after: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),

    who: _emberData.default.belongsTo('user', { async: true }),

    json_before: Ember.computed('before', function () {
      return JSON.parse(this.get('before'));
    }),

    json_after: Ember.computed('after', function () {
      return JSON.parse(this.get('after'));
    }),

    formatted_date: Ember.computed('date', function () {
      return moment(this.get('date')).format('MM/DD/YYYY');
    })
  });
});