define('cmp-dashboard/components/shared/navigation/user-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    classNames: 'user-options',
    classNameBindings: ['isOpen:user-options--open'],
    isOpen: false,

    actions: {
      logout: function logout() {
        this.get('store').unloadAll();
        this.get('session').invalidate();
      },
      showUserDetails: function showUserDetails() {
        this.set('showUserDetails', true);
      }
    }
  });
});