define('cmp-dashboard/components/shared/helpers/form/contract/contract-form', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),
    renderComplete: false,

    didInsertElement: function didInsertElement() {
      if (Ember.isEmpty(this.get('model').get('purchase_date'))) {
        var today = new Date();

        this.get('model').set('purchase_date', today);
      }
    },
    didRender: function didRender() {
      if (!this.get('renderComplete')) {
        Ember.$('input#vin').focus();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.set('renderComplete', false);
    },


    keyDown: function keyDown() {
      this.set('renderComplete', true);
    },

    keyUp: function keyUp(e) {
      if (e.target.localName === 'input' || e.target.localName === 'select') {
        this.get('application').set('unsaved', true);
      }
      this.send('formatFields');
    },

    capitalize: function capitalize(inputString) {
      var words = inputString.split(' ');
      var fullString = '';

      for (var i = 0; i < words.length; i++) {
        fullString += words[i].charAt(0).toUpperCase() + words[i].slice(1) + " ";
      }

      return fullString.substring(0, fullString.length - 1);
    },

    getVehicleDetails: function getVehicleDetails() {
      var contract = this.get('model');
      var vin = this.get('vin');

      if (vin.length !== 17) {
        return;
      }

      this.get('ajax').request('https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/' + vin + '?format=json').then(function (res) {
        var results = res.Results;

        results.forEach(function (variable) {
          if (variable.Value === null) {
            return;
          }

          switch (variable.Variable) {
            case 'Make':
              contract.set('car_make', variable.Value);
              break;
            case 'Model':
              contract.set('car_model', variable.Value);
              break;
            case 'Model Year':
              contract.set('car_year', variable.Value);
              break;
          }
        });
      });
    },

    actions: {
      calculateNextAppt: function calculateNextAppt() {
        var value = Ember.$('#dealers_sched_id').val();
        var self = this;

        if (value !== "Select Term") {
          self.get('store').find('sched', value).then(function (schedule) {

            var monthsOut = 6;

            if (schedule.get('renew')) {
              monthsOut = schedule.get('int_miles') / 1000;
            } else {
              monthsOut = schedule.get('term_months') / schedule.get('visits');
            }

            var nextDate = (0, _moment.default)(self.get('model.purchase_date'));

            if (monthsOut % 1 > 0) {
              // If monthsOut is not a whole number
              var daysOut = 30 * (monthsOut % 1); /* Average days / decimal place */
              monthsOut -= monthsOut % 1; // Remove decimal

              nextDate = nextDate.add(monthsOut, 'months');
              nextDate = nextDate.add(daysOut, 'days');
            } else {
              nextDate = nextDate.add(monthsOut, 'months');
            }

            self.get('store').find('dealer', localStorage.dealer).then(function (dealer) {
              nextDate.add(dealer.get('days_reminder'), 'days');

              self.set('model.next_app_date', nextDate.toDate());
            });
          });
        }
      },

      calculateSuggestedPrice: function calculateSuggestedPrice() {
        var value = Ember.$('#dealers_sched_id').val();
        var self = this;

        if (value !== "Select Term") {
          self.get('store').find('sched', value).then(function (schedule) {
            self.set('model.suggested_price', schedule.get('formatted_sale_price'));
          });
        }
      },

      termChanged: function termChanged(target) {
        this.send('calculateNextAppt');
        this.send('calculateSuggestedPrice');
        this.get('termChanged')(target);
      },


      formatFields: function formatFields() {
        var firstName = this.get('model.fname');
        var lastName = this.get('model.lname');
        var address1 = this.get('model.address1');
        var address2 = this.get('model.address2');
        var city = this.get('model.city');
        var car_make = this.get('model.car_make');
        var car_model = this.get('model.car_model');

        if (firstName) {
          this.set('model.fname', this.capitalize(firstName));
        }
        if (lastName) {
          this.set('model.lname', this.capitalize(lastName));
        }
        if (address1) {
          this.set('model.address1', this.capitalize(address1));
        }
        if (address2) {
          this.set('model.address2', this.capitalize(address2));
        }
        if (city) {
          this.set('model.city', this.capitalize(city));
        }
        if (car_make) {
          this.set('model.car_make', this.capitalize(car_make));
        }
        if (car_model) {
          this.set('model.car_model', this.capitalize(car_model));
        }
      },

      checkVin: function checkVin() {
        this.getVehicleDetails();
        this.get('checkVin')();
      }
    }
  });
});