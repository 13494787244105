define('cmp-dashboard/components/shared/tours/nav-tour', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);
      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              tour.cancel();
            }
          }],
          title: 'Welcome to our Updated Application',
          text: ['Certified Maintenance has been updated to help you navigate the application faster. Use the buttons below to learn more about the new navigation.']
        }
      }, {
        id: 'change-dealer',
        options: {
          attachTo: '#header bottom',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Change Dealership',
          text: ['The dealership that you have currently selected is displayed here. If you are a member of a multi-dealer group and have access to make changes on multiple dealerships, click here to change dealers.']
        }
      }, {
        id: 'nav-toggle',
        options: {
          attachTo: '.nav-toggle-button right',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Expand/Collapse Navigation Menu',
          text: ['Clicking here allows you to collapse the navigation menu, giving you more screen space for the important information. Click it again to expand it back out.']
        }
      }, {
        id: 'nav-section-dashboards',
        options: {
          attachTo: '#scroll-container right',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Navigation Links',
          text: ['This section contains links to help you move around the application. The <b>DASHBOARDS</b> section allows you to navigate between the other dashboards you have access to. The <b>TASKS</b> section allows you to navigate between the pages on this dashboard.']
        }
      }, {
        id: 'user-profile',
        options: {
          attachTo: '.user-profile right',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'User Information',
          text: ['Clicking here will open a popup menu allowing you to change your password or logout. You can also restart the navigation tour at anytime from this menu.']
        }
      }, {
        id: 'feedback',
        options: {
          attachTo: '.feedback-toggle left',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--primary',
            text: 'Close',
            type: 'next'
          }],
          title: 'User Information',
          text: ['If you are having trouble, click this button. It will open a window where you can describe the issue you are having. We will get back to you as soon as we can to help.']
        }
      }];

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['navTourCount_' + user_id]) {
          localStorage['navTourCount_' + user_id]++;
        } else {
          localStorage['navTourCount_' + user_id] = 1;
        }
      });
    }
  });
});