define('cmp-dashboard/controllers/index', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    application: Ember.inject.service(),
    queryParams: ["page", "name"],

    page: 1,
    name: '',

    init: function init() {
      this._super();
      this.checkDealer();
    },
    checkDealer: function checkDealer() {
      if (!localStorage.dealer) {
        this.set('choosingDealers', true);
      }
    },


    choosingDealers: false,

    dealerLogo: Ember.computed('application.dealer', function () {
      var dealerID = '94';
      var url = 'https://s3.amazonaws.com/images.maintenanceprogram.com/logos/dealer';

      if (this.get('application').get('dealer') && _environment.default.environment !== 'demo') {
        dealerID = this.get('application').get('dealer').get('id');
      }

      return url + dealerID + '.png';
    }),

    ownerUser: Ember.computed('application.user.role', function () {
      var role = this.get('application.user.role');

      return role === 'admin' || role === 'dealer_manager' || role === 'dealer_principal' || role === 'sponsor' || role === 'agent' || role === 'agent_rep';
    }),

    officeUser: Ember.computed('application.user.role', function () {
      var role = this.get('application.user.role');

      return role === 'office_staff';
    }),

    serviceUser: Ember.computed('application.user.role', function () {
      var role = this.get('application.user.role');

      return role === 'service';
    }),

    proTitle: Ember.computed('application.dealer.{options,agents_id.sponsors_id.pro_title}', function () {
      var dealer = this.get('application.dealer');
      if (dealer) {
        var options = dealer.get('options');
        var sponsor = dealer.get('agents_id.sponsors_id');
        var proTitle = sponsor.get('pro_title') ? sponsor.get('pro_title') : 'Certified Maintenance';

        options.forEach(function (option) {
          if (option.get('option_type') === 'program_title') {
            proTitle = option.get('option_value');
          }
        });

        return proTitle;
      } else {
        return 'Certified Maintenance';
      }
    }),

    actions: {
      openDealers: function openDealers() {
        this.transitionToRoute('dealers');
      }
    }
  });
});