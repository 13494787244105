define('cmp-dashboard/components/shared/tours/registers-main', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              localStorage['registersTourCount_' + user_id] = 100; // Make count high so it doesn't show again
              tour.cancel();
            }
          }],
          title: 'Registers',
          text: ['Click through these steps to learn about viewing and editing registers.']
        }
      }, {
        id: 'tabs',
        options: {
          attachTo: '.register-page-tabs bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Register Tabs',
          text: ['These tabs allow you to navigate between the different register types.']
        }
      }, {
        id: 'per-page',
        options: {
          attachTo: '.table-filters left',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Per Page',
          text: ['If you would like to see more registers per page, select an option from this menu.']
        }
      }, {
        id: 'table-row',
        options: {
          attachTo: '.context-menu__row top',
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'End',
            type: 'cancel'
          }],
          title: 'Register Entry',
          text: ['Each row of the table coincides with a register. You will see the basic information of the register here. Right click to view the available options for the register. <b>If the row is orange, the register is still pending</b>.']
        }
      }];

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['registersTourCount_' + user_id]) {
          localStorage['registersTourCount_' + user_id]++;
        } else {
          localStorage['registersTourCount_' + user_id] = 1;
        }
      });
    }
  });
});