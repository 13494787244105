define('cmp-dashboard/models/contractregisterpayment', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector;

  inflector.irregular("contractregisterpayment", "contractregisterpayments");

  exports.default = _emberData.default.Model.extend({
    register_class: _emberData.default.attr('string'),
    payment_method: _emberData.default.attr('string'),
    check_number: _emberData.default.attr('string'),
    check_amount: _emberData.default.attr('number'),
    remittance_type: _emberData.default.attr('string'),
    check_date: _emberData.default.attr('date'),
    paid_recvd: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    pmt_from_transfer: _emberData.default.attr('number'),
    posted_date: _emberData.default.attr('date'),
    conversion_rate: _emberData.default.attr('number'),

    contract_register_id: _emberData.default.belongsTo('contractregister', { async: true }),
    posted_by: _emberData.default.belongsTo('user', { async: true })
  });
});