define('cmp-dashboard/components/dashboard/dashboard-card/dashboard-card-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: 'card__slider__item',
    classNameBindings: ['isActive:card__slider__item--active'],

    isActive: Ember.computed('active_index', 'card_index', function () {
      return this.get('active_index') === this.get('card_index');
    }),

    smallValue: Ember.computed('card_data.value', function () {
      var valueString = this.get('card_data.value');

      if (valueString) {
        var valueStringLength = valueString.toString().length;

        return valueStringLength > 5;
      } else {
        return false;
      }
    }),

    valueSize: Ember.computed('card_data.value', function () {
      var valueString = this.get('card_data.value');

      if (valueString.length > 9) {
        return ' card__value--xs';
      } else if (valueString.length > 5) {
        return ' card__value--s';
      } else {
        return '';
      }
    }),

    click: function click() {
      window.location.href = this.get('link');
    },
    didInsertElement: function didInsertElement() {
      var item = this.$();
      var self = this;

      this.get('setHeight')(item.height());

      window.addEventListener('resize', function () {
        self.get('setHeight')(item.height());
      });
    }
  });
});