define('cmp-dashboard/components/shared/helpers/call-results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    classNames: 'call-result',

    doNotCall: false,
    doNotEmail: false,
    doNotText: false,

    init: function init() {
      this._super();
      this.setupDropdown();
    },
    setupDropdown: function setupDropdown() {
      var self = this;

      this.get('store').query('cfquestionoptionsdescription', { language: 'EN' }).then(function (options) {
        self.set('options', options);
      });
    },


    actions: {
      updateResult: function updateResult(e) {
        var result = e.target.value;

        if (result === 'No Longer has Vehicle' || result === 'Moved/Distanced') {
          this.set('doNotCall', true);
          this.set('doNotEmail', true);
          this.set('doNotText', true);
        }
        this.set('result', result);
      }
    }
  });
});