define('cmp-dashboard/models/contractregisterf', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* global moment:true */
  var inflector = _emberInflector.default.inflector;

  inflector.irregular("contractregisterf", "contractregisterfs");

  exports.default = _emberData.default.Model.extend({
    reg_type: _emberData.default.attr('string'),
    payment_method: _emberData.default.attr('string'),
    total_contract: _emberData.default.attr('number'),
    total_amount: _emberData.default.attr('number'),
    total_taxes: _emberData.default.attr('number'),
    check_number_int: _emberData.default.attr('number'),
    check_number: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('date'),
    submit_date: _emberData.default.attr('date'),
    transfer_date: _emberData.default.attr('date'),
    imported: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    finished: _emberData.default.attr('string'),
    transferred: _emberData.default.attr('string'),
    TP_batch_number: _emberData.default.attr('string'),
    users_id: _emberData.default.attr('string'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    //contract_register_id: DS.belongsTo('contractregister', { async: true }),
    //users_id: DS.belongsTo('user', { async: true }),
    //sponsor_bills_id: DS.belongsTo('sponsorbill', { async: true }),
    //approval_sponsor_bills_id: DS.belongsTo('sponsorbill', { async: true }),

    contractfs: _emberData.default.hasMany('contractf', { async: true }),

    status: Ember.computed('finished', 'transferred', function () {
      var finished = this.get('finished');
      var transferred = this.get('transferred');

      if (finished === 'N') {
        return 'New';
      } else if (finished === 'S' && transferred === 'N') {
        return 'Waiting for Sponsor Approval';
      } else if (finished === 'Y' && transferred === 'N') {
        return 'Waiting for Paper Contracts or Payment';
      } else if (finished === 'Y' && transferred === 'Y') {
        return 'In Process';
      } else {
        return '';
      }
    }),

    formatted_reg_type: Ember.computed('reg_type', function () {
      return this.get('reg_type') === 'R' ? 'Dealership Register' : 'Dealership Contracts';
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    formatted_month_year: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MMMM YYYY');
    }),

    contract_cost: Ember.computed('contractfs.@each.dealer_remittance', function () {
      var contractfs = this.get('contractfs');
      var ret = 0;

      contractfs.forEach(function (contractf) {
        ret += contractf.get('dealer_remittance') * 100;
      });

      return ret / 100;
    }),

    formatted_contract_cost: Ember.computed('contract_cost', function () {
      var contract_cost = this.get('contract_cost');

      if (!contract_cost) {
        contract_cost = 0;
      }

      return contract_cost.toFixed(2);
    }),

    formatted_total_amount: Ember.computed('total_amount', function () {
      var total_amount = this.get('total_amount');

      if (!total_amount) {
        total_amount = 0;
      }

      return total_amount.toFixed(2);
    }),

    balance: Ember.computed('contract_cost', 'total_amount', function () {
      return this.get('contract_cost') - this.get('total_amount');
    }),

    formatted_balance: Ember.computed('balance', function () {
      var balance = this.get('balance');

      return balance.toFixed(2);
    })
  });
});