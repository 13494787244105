define('cmp-dashboard/models/reportsclass', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    class: _emberData.default.attr('string'),

    reports_id: _emberData.default.belongsTo('report')
  });
});