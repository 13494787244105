define('cmp-dashboard/models/dealer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),

    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('number'),
    external_ref_id: _emberData.default.attr('number'),
    use_program_title: _emberData.default.attr('boolean'),
    use_appointment_system: _emberData.default.attr('boolean'),
    limited_contract_form: _emberData.default.attr('boolean'),
    service_reminders_only: _emberData.default.attr('boolean'),
    post_billed_contracts: _emberData.default.attr('boolean'),

    groups_id: _emberData.default.attr('number'),
    reps_id: _emberData.default.attr('number'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    tel_ex: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    owner: _emberData.default.attr('string'),
    gm: _emberData.default.attr('string'),
    sm: _emberData.default.attr('string'),
    om: _emberData.default.attr('string'),
    fm: _emberData.default.attr('string'),
    reminder_name: _emberData.default.attr('string'),
    fm_email: _emberData.default.attr('string'),
    owner_email: _emberData.default.attr('string'),
    gm_email: _emberData.default.attr('string'),
    sm_email: _emberData.default.attr('string'),
    om_email: _emberData.default.attr('string'),
    remittance_email: _emberData.default.attr('string'),
    remittance_name: _emberData.default.attr('string'),
    reminder_email: _emberData.default.attr('string'),
    reminder_report: _emberData.default.attr('string'),
    qb_customer_refnum: _emberData.default.attr('number'),
    qb_accnt_refnum: _emberData.default.attr('number'),
    qb_vendor_refnum: _emberData.default.attr('number'),
    lt_name: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    p_reminder: _emberData.default.attr('string'),
    e_reminder: _emberData.default.attr('string'),
    v_reminder: _emberData.default.attr('string'),
    t_reminder: _emberData.default.attr('string'),
    auto_confirm_mobile: _emberData.default.attr('number'),
    days_reminder: _emberData.default.attr('number'),
    p_loyalty: _emberData.default.attr('string'),
    e_loyalty: _emberData.default.attr('string'),
    v_loyalty: _emberData.default.attr('string'),
    auto_points: _emberData.default.attr('string'),
    points_loyalty: _emberData.default.attr('number'),
    q_report: _emberData.default.attr('string'),
    monthly_forfeiture: _emberData.default.attr('string'),
    car_sold: _emberData.default.attr('number'),
    contract_logins: _emberData.default.attr('string'),
    active: _emberData.default.attr('string'),
    pass: _emberData.default.attr('string'),
    active_register: _emberData.default.attr('string'),
    contract_remittance_method: _emberData.default.attr('string'),
    payment_method: _emberData.default.attr('string'),
    claim_payment_method: _emberData.default.attr('string'),
    ach_confirmed: _emberData.default.attr('number'),
    claim_method: _emberData.default.attr('string'),
    cancellation_payment_method: _emberData.default.attr('string'),
    loyalty_claim_method: _emberData.default.attr('string'),
    active_claims: _emberData.default.attr('string'),
    referrals_id: _emberData.default.attr('number'),
    service_tel: _emberData.default.attr('string'),
    service_tel_ext: _emberData.default.attr('number'),
    lost_soul_script: _emberData.default.attr('string'),
    service_reminder_script: _emberData.default.attr('string'),
    sales_followup_script: _emberData.default.attr('string'),
    sales_call_days: _emberData.default.attr('number'),
    website: _emberData.default.attr('string'),
    locale: _emberData.default.attr('string'),
    lost_soul_car_type: _emberData.default.attr('string'),
    lost_soul_days_new: _emberData.default.attr('number'),
    lost_soul_days_used: _emberData.default.attr('number'),
    lost_soul_recycle_days: _emberData.default.attr('number'),
    interest_percent: _emberData.default.attr('number'),
    billing_name: _emberData.default.attr('string'),
    billing_email: _emberData.default.attr('string'),
    reports_name: _emberData.default.attr('string'),
    reports_email: _emberData.default.attr('string'),
    redeem_rate: _emberData.default.attr('number'),
    max_redeem_dollar: _emberData.default.attr('number'),
    activation_points: _emberData.default.attr('number'),
    print_letter: _emberData.default.attr('number'),
    print_keytag: _emberData.default.attr('number'),
    print_cover: _emberData.default.attr('number'),
    send_welcome_email: _emberData.default.attr('number'),
    merged_dealers_id: _emberData.default.attr('number'),
    service_phone_text: _emberData.default.attr('string'),
    account_rep: _emberData.default.attr('string'),
    marketing_design: _emberData.default.attr('string'),
    data_source: _emberData.default.attr('string'),
    logo_interlaced: _emberData.default.attr('number'),
    dms_service: _emberData.default.attr('number'),
    form_id: _emberData.default.attr('number'),
    reinsurance_id: _emberData.default.attr('number'),
    program_title: _emberData.default.attr('string'),

    agents_id: _emberData.default.belongsTo('agent', { async: true }),
    contracts: _emberData.default.hasMany('contract', { async: true }),
    options: _emberData.default.hasMany('option', { async: true }),

    activeContracts: Ember.computed('active_register', function () {
      if (this.get('active_register') === 'Y') {
        return true;
      }

      return false;
    }),

    activeClaims: Ember.computed('active_claims', function () {
      if (this.get('active_claims') === 'Y') {
        return true;
      }

      return false;
    }),

    formatted_tel: Ember.computed('tel', function () {
      if (this.get('tel') && this.get('tel') !== '') {
        return this.get('tel').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }

      // No telephone
      return null;
    }),

    formatted_service_tel: Ember.computed('service_tel', 'tel', function () {
      var tel = this.get('tel');
      var service_tel = this.get('service_tel');

      if (tel && tel !== '' || service_tel && service_tel !== '') {
        var telToFormat = service_tel && service_tel !== '' ? service_tel : tel;
        return telToFormat.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }

      // No telephone
      return null;
    }),

    post_expiration_claims: Ember.computed('options.@each.option_type', function () {
      var options = this.get('options');
      var post_expiration_claims = false;

      if (options) {
        options.forEach(function (option) {
          if (option.get('option_type') === 'post_expiration_claims') {
            var option_value = parseInt(option.get('option_value'));

            if (option_value === 1) {
              post_expiration_claims = true;
            }
          }
        });
      }

      return post_expiration_claims;
    })
  });
});