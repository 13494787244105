define('cmp-dashboard/models/searchcontract', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fname: _emberData.default.attr(),
    lname: _emberData.default.attr(),

    groups_id: _emberData.default.attr('number'),

    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    mobile_number: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),

    car_year: _emberData.default.attr('string'),
    car_make: _emberData.default.attr('string'),
    car_model: _emberData.default.attr('string'),
    car_used: _emberData.default.attr('string'),
    vin: _emberData.default.attr('string'),
    vin8: _emberData.default.attr('string'),
    odo: _emberData.default.attr('number'),

    exp_date: _emberData.default.attr('date'),
    dms_customer_number: _emberData.default.attr('string'),
    cancel_c: _emberData.default.attr('string'),

    contract_f: _emberData.default.attr('boolean'),

    dealers_id: _emberData.default.belongsTo('dealer', {
      async: true
    }),

    contract_id: _emberData.default.belongsTo('contract', {
      async: true
    }),

    contract_f_id: _emberData.default.belongsTo('contractf', {
      async: true
    }),

    claims: Ember.computed.alias('contract_id.claims'),
    claimls: _emberData.default.hasMany('claiml', {
      async: true
    }),
    // claimts: alias('contract_id.claimts'),
    phonecalls: _emberData.default.hasMany('phonecall', {
      async: true
    }),
    reminders: _emberData.default.hasMany('reminder', {
      async: true
    }),
    contractupdates: _emberData.default.hasMany('contractupdate', {
      async: true
    }),

    dealer_name: Ember.computed.alias('dealers_id.name'),

    coupons: Ember.computed('contract_id.dealers_sched_id.coupons', 'contract_f_id.dealers_sched_id.coupons', function () {
      var contract_sched = this.get('contract_id.dealers_sched_id');
      var contract_f_sched = this.get('contract_f_id.dealers_sched_id');

      if (contract_sched) {
        return contract_sched.get('coupons');
      } else if (contract_f_sched) {
        return contract_f_sched.get('coupons');
      } else {
        return null;
      }
    }),

    claimts: Ember.computed('contract_id.claimts', 'contract_f_id.claimts', function () {
      var contract_claimts = this.get('contract_id.claimts');
      var contract_f_claimts = this.get('contract_f_id.claimts');

      if (contract_claimts) {
        return contract_claimts;
      } else if (contract_f_claimts) {
        return contract_f_claimts;
      } else {
        return null;
      }
    }),

    exp_date_days: Ember.computed('exp_date', function () {
      var exp_date = moment(this.get('exp_date'));
      var today = moment();

      return exp_date.diff(today, 'days');
    }),

    isCancelled: Ember.computed('cancel_c', function () {
      return this.get('cancel_c') === 'Y' || this.get('cancel_c') === 'P' || this.get('cancel_c') === 'W' || this.get('cancel_c') === 'X';
    }),

    isExpired: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= -90) {
        return true;
      }

      return false;
    }),

    isInDanger: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= 30 && expires > -90) {
        return true;
      }

      return false;
    }),

    isInWarning: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= 60 && expires > 30) {
        return true;
      }

      return false;
    }),

    isGood: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires > 60) {
        return true;
      }

      return false;
    })
  });
});