define('cmp-dashboard/routes/auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),

    queryParams: {
      email: { refreshModel: true },
      password: { refreshModel: true }
    },

    email: null,
    password: null,

    beforeModel: function beforeModel(transition) {
      var _this = this;

      sessionStorage.clear();

      localStorage.email = transition.queryParams.email;

      var credentials = transition.queryParams;

      this.get('session').authenticate('authenticator:custom', credentials).catch(function (reason) {
        _this.set('errorMessage', reason);
      });
    }
  });
});