define('cmp-dashboard/models/tp', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dealercost: _emberData.default.attr(),
    retail: _emberData.default.attr(),
    historical: _emberData.default.attr()
  });
});