define('cmp-dashboard/components/shared/popup/user-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    application: Ember.inject.service(),

    elementId: 'user-details',
    classNames: 'page-alert-container',
    classNameBindings: ['isOpen:page-alert-container--open'],
    isOpen: false,
    hideCancel: false,

    current_password: '',
    new_password: '',
    confirm_password: '',

    current_password_type: 'password',
    new_password_type: 'password',

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      Ember.run.later(this, function () {
        self.set('isOpen', true);
      }, 100);
    },


    click: function click(e) {
      if (Ember.$(e.target).hasClass('page-alert-container')) {
        this.send('onCancel');
      }
    },

    actions: {
      toggleNew: function toggleNew() {
        if (this.get('new_password_type') === 'password') {
          this.set('new_password_type', 'text');
        } else {
          this.set('new_password_type', 'password');
        }
      },
      toggleCurrent: function toggleCurrent() {
        if (this.get('current_password_type') === 'password') {
          this.set('current_password_type', 'text');
        } else {
          this.set('current_password_type', 'password');
        }
      },
      onChange: function onChange() {
        if (this.get('new_password').length < 8 && !Ember.isEmpty(this.get('new_password'))) {
          this.set('errors', [{ message: 'Password must be at least 8 characters long.' }]);
          return;
        }
        if (this.get('new_password') !== this.get('confirm_password')) {
          this.set('errors', [{ message: 'Passwords do not match.' }]);
          return;
        }
        this.set('errors', []);
      },
      close: function close() {
        this.set('isOpen', false);

        var self = this;
        Ember.run.later(this, function () {
          self.sendAction('close');
        }, 600);
      },
      onCancel: function onCancel() {
        this.send('close');
      },
      onConfirm: function onConfirm() {
        var user = this.get('application.user');

        var data = {
          current_password: this.get('current_password'),
          pwd: this.get('new_password')
        };

        var self = this;
        return this.get('ajax').request('/api/users/' + user.get('id'), {
          method: 'PUT',
          data: JSON.stringify(data)
        }).then(function (response) {
          if (response.success) {
            self.get('toast').success('Password successfully changed!');
            self.send('close');
          } else {
            self.set('errors', [response.message]);
            self.get('toast').error('There was a problem changing the password! Please try again later or contact support@performanceadmin.com');
          }
        }).catch(function (errors) {
          self.set('errors', errors.errors);
          self.get('toast').error('There was a problem changing the password! Please try again later or contact support@performanceadmin.com');
        });
      }
    }
  });
});