define('cmp-dashboard/components/shared/popup/page-overlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    toast: Ember.inject.service(),
    classNames: 'dealer-list page-overlay page-overlay--white',
    classNameBindings: ['isOpen:page-overlay--open'],
    isOpen: false,

    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      if (!this.get('isOpen')) {
        Ember.run.later(this, function () {
          self.set('isOpen', true);
        }, 100);
      }
    },


    actions: {
      cancel: function cancel() {
        if (this.get('disableClose')) {
          this.get('toast').error(this.get('closeMessage'));
        } else {
          this.set('isOpen', false);

          var self = this;
          Ember.run.later(this, function () {
            self.sendAction('onCancel');
          }, 1000);
        }
      }
    }
  });
});