define('cmp-dashboard/models/reminderscalldetailslist', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contract_id: _emberData.default.belongsTo('contract', { async: true }),
    claimt_id: _emberData.default.belongsTo('claimt', { async: true }),
    claim_id: _emberData.default.belongsTo('claim', { async: true }),

    calls: _emberData.default.attr()
  });
});