define('cmp-dashboard/components/shared/tours/service-reminders-contract', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tour: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var self = this;
      var tour = this.get('tour');

      tour.on('cancel', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('complete', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      tour.on('destroy', function () {
        tour.off('cancel');
        self.get('tourComplete')();
      });

      if (_environment.default.environment !== 'demo') {
        this.startTour();
      } else {
        self.get('tourComplete')();
      }
    },
    startTour: function startTour() {
      var user_id = this.get('application.user.id') ? this.get('application.user.id').substring(0, 7) : 'undefined';
      var tour = this.get('tour');

      tour.set('defaultStepOptions', {
        scrollTo: false,
        highlightClass: 'highlight'
      });
      tour.set('modal', true);
      tour.set('requiredElements', []);

      var steps = [{
        id: 'intro',
        options: {
          buttons: [{
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }, {
            classes: 'btn btn--default',
            text: "Don't show this again",
            action: function action() {
              localStorage['serviceReminderContractTourCount_' + user_id] = 100; // Make count high so it doesn't show again
              tour.cancel();
            }
          }],
          title: 'Service Reminder Contract',
          text: ['Click through these steps to learn about winning back a service reminder contract.']
        }
      }, {
        id: 'contact-information',
        options: {
          attachTo: '.contact-card bottom',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Contact Information',
          text: ['The basic information for the customer is shown here. A list of past contacts, if any have been made, are shown on the right of the contact information.']
        }
      }, {
        id: 'contact-method',
        options: {
          attachTo: {
            element: '.contact-card__options',
            on: 'top'
          },
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(0);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Contact Options',
          text: ['If available, you can switch between <b>calling</b>, <b>emailing</b>, or <b>texting</b> to contact the customer.']
        }
      }, {
        id: 'sidebar',
        options: {
          attachTo: '#contact-sidebar left',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(Ember.$('.coupons-title').offset().top);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--default',
            text: 'Next',
            type: 'next'
          }],
          title: 'Services',
          text: ['You will see a list of the services for this contract here. It will show which ones are expired, available, and which are redeemed.']
        }
      }, {
        id: 'contact-customer',
        options: {
          attachTo: '#contact-customer top',
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              Ember.run.scheduleOnce('afterRender', this, function () {
                Ember.$('#page-content').scrollTop(Ember.$('#contact-customer').offset().top);
                resolve();
              });
            });
          },
          buttons: [{
            classes: 'btn btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--danger',
            text: 'Exit',
            type: 'cancel'
          }],
          title: 'Contact Customer',
          text: ["Based on the contact method, you can use this section to contact the customer. For calls, read through the script while talking to the customer. For emails & texts, type out a message to send to the customer. <b>Don't forget to save the result when you are done!</b>"]
        }
      }];

      tour.addSteps(steps).then(function () {
        tour.start();

        if (localStorage['serviceReminderContractTourCount_' + user_id]) {
          localStorage['serviceReminderContractTourCount_' + user_id]++;
        } else {
          localStorage['serviceReminderContractTourCount_' + user_id] = 1;
        }
      });
    }
  });
});