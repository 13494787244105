define('cmp-dashboard/components/shared/partials/dealers/top-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'searchbar searchbar--outline',
    queryParams: ['q'],
    q: '',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$('#dealer-name').focus();
    },


    placeholder: Ember.computed('type', function () {
      var type = this.get('type');

      if (type === 'sponsors') {
        return 'Sponsor name';
      }

      if (type === 'agents') {
        return 'Agent name';
      }

      return 'Dealer name';
    })
  });
});