define('cmp-dashboard/components/shared/theme/expandable-table-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'table__row',
    click: function click(e) {
      var row = Ember.$(e.target).closest('.table__row');
      var rowDetails = Ember.$(row).find('.table__row__extra-details');
      var allDetails = Ember.$('.table__row__extra-details');

      if (e.target.localName === 'input' || e.target.localName === 'select' || e.target.localName === 'button' || e.target.localName === 'a' || e.target.className === 'tooltip-overlay' || Ember.$(e.target).closest('.tooltip-overlay').length !== 0 || Ember.$(e.target).closest('.table__row__extra-details').length !== 0 || Ember.$(e.target).hasClass('btn')) {
        return;
      }
      if (!rowDetails.hasClass('table__row__extra-details--open')) {
        allDetails.removeClass('table__row__extra-details--open');
        rowDetails.addClass('table__row__extra-details--open');
      } else {
        allDetails.removeClass('table__row__extra-details--open');
      }
    }
  });
});