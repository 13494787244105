define('cmp-dashboard/components/shared/helpers/form/validated-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;

      var formId = '#' + this.get('formId');

      Ember.$(formId).validate({
        rules: self.rules,
        errorPlacement: function errorPlacement(error, element) {
          if (error.length === 1 && error[0].innerText.length === 0) {
            return;
          }
          if (element.is(':radio')) {
            error.appendTo(element.parent().next().next());
          } else if (element.is(':checkbox')) {
            error.appendTo(element.next());
          } else {
            error.appendTo(element.parent());
          }
        },
        success: function success(label) {
          label.parent().find('.error-icon').addClass('hidden');
          label.parent().find('.success-icon').removeClass('hidden');
        },
        highlight: function highlight(element) {
          Ember.$(element).parent().find('.success-icon').addClass('hidden');
          Ember.$(element).parent().find('.error-icon').removeClass('hidden');
        },
        onfocusout: function onfocusout(element) {
          Ember.$(element).valid();
        }
      });
    }
  });
});