define('cmp-dashboard/components/shared/helpers/form/state-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    value: 'OH',

    actions: {
      selectState: function selectState(state) {
        this.set('value', state);
      }
    }
  });
});