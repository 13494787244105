define('cmp-dashboard/components/shared/helpers/tools/table-sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'a',

    click: function click() {
      this.get('sortBy')(this.get('sort_key'));
    }
  });
});