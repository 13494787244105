define('cmp-dashboard/components/dashboard/layouts/owner-layout', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    tagName: 'section',
    elementId: 'dashboard-cards',
    classNames: 'cards',

    active_contracts_loading: false,
    lost_opportunities_loading: false,
    forfeiture_loading: false,
    claims_loading: false,
    repurchases_loading: false,

    // Link URLs
    active_contracts_link: '/reports/#/reports/contract_reserve',
    lost_opportunities_link: '/service/#/lost-opportunities',
    forfeiture_link: '/reports/#/reports/expired_contract_reserve',
    claims_link: '/reports/#/reports/repair_orders_by_month',
    repurchases_link: '/reports/#/reports/vehicle_repurchases',

    didReceiveAttrs: function didReceiveAttrs() {
      // Set default values for the cards
      this.set('active_contracts_data', []);
      this.set('lost_opportunity_data', []);
      this.set('claims_data', []);
      this.set('forfeiture_data', [{
        value: 0,
        label: 'Forfeiture',
        sublabel: 'ITD'
      }]);
      this.set('repurchase_data', []);

      this.setCardValues();
    },
    setCardValues: function setCardValues() {
      this.setActiveContracts();
      this.setLostOpportunities();
      this.setClaims();
      this.setRepurchases();
      this.setForfeiture();
    },
    setActiveContracts: function setActiveContracts() {
      var self = this;

      this.set('active_contracts_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/executive_report/total_contracts').then(function (total_contracts) {

        var active_contracts = [];
        active_contracts.push({
          value: total_contracts.data[0][1],
          label: 'Active Contracts'
        });

        if (!self.get('isDestroyed')) {
          self.set('active_contracts_loading', false);
          self.set('active_contracts_data', active_contracts);
        }
      });
    },
    setLostOpportunities: function setLostOpportunities() {
      var self = this;

      this.set('lost_opportunities_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/lost_opportunities/monthly').then(function (lost_opportunities) {

        var newLOs = 0;
        var callsMade = 0;
        var emailsSent = 0;
        var textsSent = 0;

        // Get the new lost opportunities from last month
        lost_opportunities.data.newLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, 'months').month();

          if (month === last_month) {
            newLOs = lost_op[1];
          }
        });

        // Get the lost opportunity calls from last month
        lost_opportunities.data.callLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, 'months').month();

          if (month === last_month) {
            callsMade = lost_op[1];
          }
        });

        // Get the lost opportunity emails from last month
        lost_opportunities.data.emailLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, 'months').month();

          if (month === last_month) {
            emailsSent = lost_op[1];
          }
        });

        // Get the lost opportunity texts from last month
        lost_opportunities.data.textLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, 'months').month();

          if (month === last_month) {
            textsSent = lost_op[1];
          }
        });

        var lost_opportunities_data = [];
        lost_opportunities_data.push({
          value: newLOs ? newLOs : 0,
          label: 'New Lost Opportunities',
          sublabel: 'Last Month'
        });
        lost_opportunities_data.push({
          value: callsMade ? callsMade : 0,
          label: 'Calls Made',
          sublabel: 'Last Month'
        });
        lost_opportunities_data.push({
          value: emailsSent ? emailsSent : 0,
          label: 'Emails Sent',
          sublabel: 'Last Month'
        });
        lost_opportunities_data.push({
          value: textsSent ? textsSent : 0,
          label: 'Texts Sent',
          sublabel: 'Last Month'
        });

        if (!self.get('isDestroyed')) {
          self.set('lost_opportunities_loading', false);
          self.set('lost_opportunity_data', lost_opportunities_data);
        }
      });
    },
    setClaims: function setClaims() {
      var self = this;

      this.set('claims_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/repair_orders_by_month/visits?start_date=2000-01-01').then(function (visits) {
        self.get('ajax').request('/reports/' + localStorage.dealer + '/repair_orders_by_month/claims?start_date=2000-01-01').then(function (income) {

          var itd_visits = 0;
          var itd_income = 0;

          visits.data.forEach(function (visit) {
            itd_visits += visit.y;
          });

          income.data.forEach(function (amount) {
            itd_income += amount.y;
          });

          var claims_data = [];
          claims_data.push({
            value: itd_visits,
            label: 'Claim Visits',
            sublabel: 'ITD'
          });
          claims_data.push({
            value: '$' + (itd_income ? itd_income.toFixed(2) : '0'),
            label: 'Claim Income',
            sublabel: 'ITD'
          });

          if (!self.get('isDestroyed')) {
            self.set('claims_loading', false);
            self.set('claims_data', claims_data);
          }
        });
      });
    },
    setForfeiture: function setForfeiture() {
      var self = this;

      this.set('forfeiture_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/expired_contract_reserve/reserve_ratios?start_date=2000-01-01').then(function (reserve_ratios) {

        var forfeiture_data = [];
        forfeiture_data.push({
          value: '$' + (reserve_ratios.meta.forfeiture_income ? reserve_ratios.meta.forfeiture_income.toFixed(2) : '0'),
          label: 'Forfeiture Income',
          sublabel: 'ITD'
        });
        forfeiture_data.push({
          value: (reserve_ratios.meta.forfeiture_percent ? reserve_ratios.meta.forfeiture_percent.toFixed(2) : '0') + '%',
          label: 'Forfeiture Percent',
          sublabel: 'ITD'
        });
        forfeiture_data.push({
          value: '$' + (reserve_ratios.meta.forfeiture_per_contract ? reserve_ratios.meta.forfeiture_per_contract.toFixed(2) : 0),
          label: 'Forfeiture per Contract',
          sublabel: 'ITD'
        });

        if (!self.get('isDestroyed')) {
          self.set('forfeiture_loading', false);
          self.set('forfeiture_data', forfeiture_data);
        }
      });
    },
    setRepurchases: function setRepurchases() {
      var self = this;

      this.set('repurchases_loading', true);
      this.get('ajax').request('/reports/' + localStorage.dealer + '/repurchases_by_year').then(function (repurchases) {
        self.set('repurchases_loading', false);

        var itd = 0;

        repurchases.data.forEach(function (year) {
          itd += year[1];
        });

        var repurchase_data = [];
        repurchase_data.push({
          value: itd ? itd : 0,
          label: 'Repurchases',
          sublabel: 'ITD'
        });

        if (!self.get('isDestroyed')) {
          self.set('repurchases_loading', false);
          self.set('repurchase_data', repurchase_data);
        }
      });
    }
  });
});