define('cmp-dashboard/components/shared/popup/login-prompt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),

    classNames: 'prompt-container',
    classNameBindings: ['isOpen:prompt-container--open'],
    isOpen: false,

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      Ember.run.later(this, function () {
        self.set('isOpen', true);
      }, 100);
    },


    click: function click(e) {
      if (Ember.$(e.target).hasClass('prompt-container')) {
        this.send('close');
      }
    },

    actions: {
      close: function close() {
        this.set('isOpen', false);

        var self = this;
        Ember.run.later(this, function () {
          self.sendAction('close');
        }, 600);
      },
      logout: function logout() {
        this.set('isOpen', false);

        var self = this;
        Ember.run.later(this, function () {
          self.get('store').unloadAll();
          self.get('session').invalidate();
          self.sendAction('close');
        }, 600);
      }
    }
  });
});