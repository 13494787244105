define('cmp-dashboard/components/date-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    clock: Ember.inject.service('global-clock'),

    classNames: 'date-time-bar',

    date: Ember.computed('clock.date', function () {
      return (0, _moment.default)(this.get('clock.date')).format('dddd, MMMM Do, YYYY');
    }),

    time: Ember.computed('clock.date', function () {
      return (0, _moment.default)(this.get('clock.date')).format('HH:mm:ss A');
    })
  });
});