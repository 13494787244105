define('cmp-dashboard/components/shared/helpers/form/checkbox-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'checkbox',
    change: function change() {
      this.set('checked', !this.get('checked'));

      // send change action if set
      if (this.get('changed')) {
        this.get('changed')();
      }
    }
  });
});