define('cmp-dashboard/models/loyalty', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    auto_points: _emberData.default.attr('number'),
    points: _emberData.default.attr('number'),
    web_users_id: _emberData.default.attr('string'),
    active: _emberData.default.attr('number'),

    loyalty_category_id: _emberData.default.belongsTo('loyaltycategory'),
    dealers_id: _emberData.default.belongsTo('dealer'),

    formatted_points_per_dollar: Ember.computed('points', function () {
      return this.get('points').toFixed(2);
    })
  });
});