define('cmp-dashboard/routes/dealers', ['exports', 'ember-cli-pagination/remote/route-mixin', 'cmp-dashboard/routes/index'], function (exports, _routeMixin, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _index.default.extend(_routeMixin.default, {
    application: Ember.inject.service(),
    perPage: 15,
    previousName: '',

    queryParams: {
      page: { refreshModel: true },
      perPage: { refreshModel: true },
      name: { refreshModel: true }
    },

    model: function model(params) {
      if (params.name && params.name !== this.get('previousName')) {
        this.set('previousName', params.name);

        params.page = 1;
        this.set('page', 1);
        this.controllerFor('dealers').set('page', 1);
      }

      params.sort = 'name';
      params.active = 'Y';

      params.paramMapping = {
        page: "page",
        perPage: "per_page",
        total_pages: "pages"
      };

      return this.findPaged('dealer', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      var self = this;

      self.get('application').set('hasGroup', true);

      model.then(function (dealers) {
        if (dealers.get('length') === 1 && controller.get('page') === 1 && !controller.get('name')) {
          var dealer_id = dealers.get('firstObject').get('id');
          localStorage.dealer = dealer_id;
          self.get('application').set('hasGroup', false);

          self.get('store').findRecord('dealer', dealer_id).then(function (dealer_obj) {
            var agent_id = dealer_obj.get('agents_id.id');
            sessionStorage.agent = agent_id;
            self.get('store').findRecord('agent', agent_id).then(function (agent_obj) {
              localStorage.sponsor = agent_obj.get('sponsors_id.id');
            });
          });

          if (typeof ga === 'function') {
            ga('set', 'dimension1', dealers.get('firstObject').get('name'));
          }

          self.controllerFor('application').set('dealer', dealers.get('firstObject'));

          if (self.get('application.previousRoute')) {
            self.transitionTo(self.get('application.previousRoute'));
          } else {
            self.transitionTo('index');
          }
        }
      });
    }
  });
});