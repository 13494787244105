define('cmp-dashboard/components/shared/popup/page-alert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    classNames: 'page-alert-container',
    classNameBindings: ['isOpen:page-alert-container--open'],
    isOpen: false,
    hideCancel: false,

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      Ember.run.later(this, function () {
        self.set('isOpen', true);
      }, 10);
    },


    click: function click(e) {
      if (Ember.$(e.target).hasClass('page-alert-container')) {
        this.send('onCancel');
      }
    },

    actions: {
      onCancel: function onCancel() {
        this.set('isOpen', false);

        var self = this;
        Ember.run.later(this, function () {
          self.sendAction('onCancel');
        }, 600);
      },
      onConfirm: function onConfirm() {
        this.set('isOpen', false);
        this.get('application').set('unsaved', false);

        var self = this;
        Ember.run.later(this, function () {
          self.sendAction('onConfirm');
        }, 600);
      }
    }
  });
});