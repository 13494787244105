define('cmp-dashboard/models/phonecall', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    call_date: _emberData.default.attr('date'),
    do_not_call: _emberData.default.attr('number'),
    do_not_email: _emberData.default.attr('number'),
    do_not_mail: _emberData.default.attr('number'),
    do_not_text: _emberData.default.attr('number'),
    comments: _emberData.default.attr('string'),
    contact_method: _emberData.default.attr('string'),
    cf_forms_id: _emberData.default.attr('number'),

    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),
    cfanswer: _emberData.default.belongsTo('cfanswer', { async: true }),

    formatted_call_date: Ember.computed('call_date', function () {
      return moment(this.get('call_date')).format('M/DD/YYYY');
    }),

    formatted_call_time: Ember.computed('call_date', function () {
      return moment(this.get('call_date')).format('h:mm A');
    }),

    user_name: Ember.computed.alias('web_users_id.name')
  });
});