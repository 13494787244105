define('cmp-dashboard/components/shared/helpers/tools/table-header-sort', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'table__row__cell table__row__cell--header  table__row__cell--sortable',

    actions: {
      sort: function sort(key) {
        this.get('sort')(key);
      }
    }
  });
});