define('cmp-dashboard/models/contract', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fname: _emberData.default.attr(),
    lname: _emberData.default.attr(),

    contract_register_id: _emberData.default.attr('number'),
    contract_register_items_id: _emberData.default.attr('number'),
    dealers_sched_id: _emberData.default.belongsTo('sched', {
      async: true
    }),
    groups_id: _emberData.default.attr('number'),
    customers_id: _emberData.default.attr('number'),

    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    mobile_number: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),

    car_year: _emberData.default.attr('string'),
    car_make: _emberData.default.attr('string'),
    car_model: _emberData.default.attr('string'),
    car_used: _emberData.default.attr('string'),
    vin: _emberData.default.attr('string'),
    vin8: _emberData.default.attr('string'),
    odo: _emberData.default.attr('number'),
    no_longer_owns: _emberData.default.attr('boolean'),

    salesman_id: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('utc'),
    purchase_date: _emberData.default.attr('utc'),
    prev_app_date: _emberData.default.attr('utc'),
    next_app_date: _emberData.default.attr('utc'),
    manual_appt_date: _emberData.default.attr('number'),
    exp_date: _emberData.default.attr('utc'),
    l_next_date: _emberData.default.attr('utc'),
    l_exp_date: _emberData.default.attr('utc'),
    loyalty_balance: _emberData.default.attr('number'),
    initial_loyalty_balance: _emberData.default.attr('number'),
    valid_email: _emberData.default.attr('number'),
    remind_counter: _emberData.default.attr('number'),
    remind_miles: _emberData.default.attr('number'),
    external_ref_id: _emberData.default.attr('string'),
    external_ref_source: _emberData.default.attr('string'),
    dms_customer_number: _emberData.default.attr('string'),
    dms_deal_number: _emberData.default.attr('string'),
    dms_stock_number: _emberData.default.attr('string'),
    customer_price: _emberData.default.attr('number'),
    imported: _emberData.default.attr('string'),
    remind_claim: _emberData.default.attr('string'),
    remind_pm: _emberData.default.attr('string'), // post card - service reminders
    remind_em: _emberData.default.attr('string'), // email - service reminders
    remind_vm: _emberData.default.attr('string'), // phone call - service reminders
    remind_tm: _emberData.default.attr('string'), // text - service reminders
    remind_pl: _emberData.default.attr('string'), // post card - loyalty reminders
    remind_el: _emberData.default.attr('string'), // email - loyalty reminders
    remind_tl: _emberData.default.attr('string'), // text- loyalty reminders
    reminder_report: _emberData.default.attr('string'),
    cancel_c: _emberData.default.attr('string'),
    cancel_date: _emberData.default.attr('date'),
    cancel_percent: _emberData.default.attr('number'),
    cancel_sys_date: _emberData.default.attr('date'),
    cancel_comment: _emberData.default.attr('string'),
    upgrade_from_contracts_id: _emberData.default.attr('number'),
    upgrade_to_contracts_id: _emberData.default.attr('number'),
    agent_comm: _emberData.default.attr('string'),
    tp_processed: _emberData.default.attr('string'),
    reserve: _emberData.default.attr('string'),
    forfeit_paid: _emberData.default.attr('string'),
    forfeit_date: _emberData.default.attr('date'),
    monthly_acnt_update: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    return_fee_comm: _emberData.default.attr('number'),
    last_reminder_date: _emberData.default.attr('utc'),
    lost_soul_call_date: _emberData.default.attr('utc'),
    saved_soul: _emberData.default.attr('number'),
    summary_lost_soul_id_bak: _emberData.default.attr('number'),
    summary_lost_soul_id: _emberData.default.attr('number'),
    historical: _emberData.default.attr('number'),
    updated_historical: _emberData.default.attr('number'),
    child_contracts_id: _emberData.default.attr('number'),
    parent_contracts_id: _emberData.default.attr('number'),
    lienholder_name: _emberData.default.attr('string'),
    lienholder_tel: _emberData.default.attr('string'),
    lienholder_address: _emberData.default.attr('string'),
    lienholder_city: _emberData.default.attr('string'),
    lienholder_state: _emberData.default.attr('string'),
    lienholder_zip: _emberData.default.attr('string'),
    cancel_fee: _emberData.default.attr('number'),
    cancel_miles: _emberData.default.attr('number'),
    cancel_reason: _emberData.default.attr('string'),
    cancel_method: _emberData.default.attr('string'),

    sale_price: Ember.computed.alias('dealers_sched_id.sale_price'),
    dealer_remittance: Ember.computed.alias('dealers_sched_id.dealer_remittance'),
    pac_remittance: Ember.computed.alias('dealers_sched_id.pac_remittance'),

    dealers_id: _emberData.default.belongsTo('dealer', {
      async: true
    }),

    claims: _emberData.default.hasMany('claim', {
      async: true
    }),
    claimls: _emberData.default.hasMany('claiml', {
      async: true
    }),
    claimts: _emberData.default.hasMany('claimt', {
      async: true
    }),
    claimpes: _emberData.default.hasMany('claimpe', {
      async: true
    }),
    phonecalls: _emberData.default.hasMany('phonecall', {
      async: true
    }),
    reminders: _emberData.default.hasMany('reminder', {
      async: true
    }),
    replies: _emberData.default.hasMany('reply', {
      async: true
    }),
    contractupdates: _emberData.default.hasMany('contractupdate', {
      async: true
    }),
    contractcomments: _emberData.default.hasMany('contractcomment', {
      async: true
    }),
    contactpreferences: _emberData.default.hasMany('contactpreference', {
      async: true
    }),

    dealer_name: Ember.computed.alias('dealers_id.name'),
    coupons: Ember.computed.alias('dealers_sched_id.coupons'),

    formatted_car_used: Ember.computed('car_used', function () {
      return this.get('car_used') === 'N' ? 'New' : 'Used';
    }),

    /**
     * Combine first name and last name
     *
     * @return string
     */
    fullName: Ember.computed('fname', 'lname', function () {
      return this.get('fname') + ' ' + this.get('lname');
    }),

    /**
     * Combine address information
     *
     * @return string
     */
    fullAddress: Ember.computed('address1', 'address2', 'city', 'state', 'zip', 'zip2', function () {
      // Get zip format
      var zip = this.get('zip2') ? this.get('zip') + '-' + this.get('zip2') : this.get('zip');
      var address = this.get('address2') ? this.get('address1') + ' ' + this.get('address2') : this.get('address1');

      return address + ', ' + this.get('city') + ', ' + this.get('state') + ' ' + zip;
    }),

    /**
     * Checks to see if the car is used.
     *
     * @return bool
     */
    isCarUsed: Ember.computed('car_used', function () {
      return this.get('car_used') === 'N' ? false : true;
    }),

    loyalty_value: Ember.computed('loyalty_balance', 'dealers_id.redeem_rate', function () {
      var redeem_rate = this.get('dealers_id').get('redeem_rate');

      return this.get('loyalty_balance') * redeem_rate / 100;
    }),

    formatted_loyalty_value: Ember.computed('loyalty_value', function () {
      var loyalty_value = parseFloat(this.get('loyalty_value'));

      if (!loyalty_value || isNaN(loyalty_value)) {
        loyalty_value = 0;
      }

      return '$' + loyalty_value.toFixed(2);
    }),

    formatted_customer_price: Ember.computed('customer_price', function () {
      var price = parseFloat(this.get('customer_price'));

      if (!price || isNaN(price)) {
        price = 0;
      }

      return '$' + price.toFixed(2);
    }),

    formatted_exp_date: Ember.computed('exp_date', function () {
      return moment(this.get('exp_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_lost_soul_call_date: Ember.computed('lost_soul_call_date', function () {
      var contact_date = moment(this.get('lost_soul_call_date'), 'YYYY-MM-DD');

      if (contact_date.isValid()) {
        return contact_date.format('MM/DD/YYYY');
      } else {
        return '';
      }
    }),

    formatted_next_app_date: Ember.computed('next_app_date', function () {
      return moment(this.get('next_app_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_purchase_date: Ember.computed('purchase_date', function () {
      return moment(this.get('purchase_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_last_reminder_date: Ember.computed('last_reminder_date', function () {
      return moment(this.get('last_reminder_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_prev_app_date: Ember.computed('prev_app_date', function () {
      return moment(this.get('prev_app_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    readable_prev_app_date: Ember.computed('prev_app_date', function () {
      return moment(this.get('prev_app_date'), 'YYYY-MM-DD').format('MMMM DD, YYYY');
    }),

    formatted_tel: Ember.computed('tel', function () {
      if (this.get('tel') && this.get('tel') !== '') {
        return this.get('tel').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }

      // No telephone
      return null;
    }),

    formatted_mobile_number: Ember.computed('mobile_number', function () {
      if (this.get('mobile_number') && this.get('mobile_number') !== '') {
        return this.get('mobile_number').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }),

    /**
     * Combine car information
     *
     * @return string
     */
    car: Ember.computed('car_year', 'car_make', 'car_model', function () {
      if (this.get('car_year') && this.get('car_make') && this.get('car_model')) {
        return this.get('car_year') + ' ' + this.get('car_make') + ' ' + this.get('car_model');
      }

      // No car??
      return null;
    }),

    exp_date_days: Ember.computed('exp_date', function () {
      var exp_date = moment(this.get('exp_date'), 'YYYY-MM-DD');
      var today = moment();

      return exp_date.diff(today, 'days');
    }),

    days_past_app_date: Ember.computed('next_app_date', function () {
      var next_app_date = moment(this.get('next_app_date'), 'YYYY-MM-DD');
      var today = moment();

      var days_past = today.diff(next_app_date, 'days');

      if (days_past > 0) {
        return days_past;
      } else {
        return 'N/A';
      }
    }),

    formatted_exp_within: Ember.computed('exp_date', function () {
      if (this.get('isExpired')) {
        return this.get('formatted_exp_date');
      } else {
        var expires = this.get('exp_date_days');

        if (expires === 0) {
          return 'Expiring Today';
        } else if (expires === 1) {
          return 'Expiring Tomorrow';
        } else if (expires < 0) {
          return 'Expired ' + Math.abs(expires) + ' days ago';
        } else {
          return 'Expiring in ' + expires + ' days';
        }
      }
    }),

    formatted_cancel_date: Ember.computed('cancel_date', function () {
      return moment(this.get('cancel_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_cancel_system_date: Ember.computed('cancel_sys_date', function () {
      return moment(this.get('cancel_sys_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    isCancelled: Ember.computed('cancel_c', function () {
      return this.get('cancel_c') === 'Y' || this.get('cancel_c') === 'P' || this.get('cancel_c') === 'W' || this.get('cancel_c') === 'X';
    }),

    isExpired: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= -30) {
        return true;
      }

      return false;
    }),

    isInDanger: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= 30 && expires > -30) {
        return true;
      }

      return false;
    }),

    isInWarning: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires <= 60 && expires > 30) {
        return true;
      }

      return false;
    }),

    isGood: Ember.computed('exp_date_days', function () {
      var expires = this.get('exp_date_days');

      if (expires > 60) {
        return true;
      }

      return false;
    }),

    textingIsAllowed: Ember.computed('remind_tl', function () {
      return this.get('remind_tl') === 'f' ? false : true;
    }),

    last_visit: Ember.computed('claims.@each.claim_date', 'claimts.@each.claim_date', function () {
      var claims = this.get("claims");
      var claimts = this.get("claimts");
      var last_visit = moment('1970-01-01', 'YYYY-MM-DD');

      if (claims) {
        claims.forEach(function (claim) {
          var claim_date = moment(claim.get('claim_date'), 'YYYY-MM-DD');

          if (claim_date > last_visit) {
            last_visit = claim_date;
          }
        });
      }

      if (claimts) {
        claimts.forEach(function (claimt) {
          var claim_date = moment(claimt.get('claim_date'), 'YYYY-MM-DD');

          if (claim_date > last_visit) {
            last_visit = claim_date;
          }
        });
      }

      if (last_visit.format('YYYY-MM-DD') === '1970-01-01') {
        return 'N/A';
      }

      return last_visit.format('MM/DD/YYYY');
    }),

    last_reminder: Ember.computed('reminders.@each.reminder_date', function () {
      var reminders = this.get("reminders");
      var last_reminder = moment('1970-01-01', 'YYYY-MM-DD');
      var last_method = 'Unknown';

      if (reminders) {
        reminders.forEach(function (reminder) {
          var reminder_date = moment(reminder.get('reminder_date', 'YYYY-MM-DD'));

          if (reminder_date > last_reminder) {
            last_reminder = reminder_date;
            last_method = reminder.get('method');
          }
        });
      }

      if (last_reminder.format('YYYY-MM-DD') === '1970-01-01') {
        return 'N/A';
      }

      return last_reminder.format('MM/DD/YYYY') + ' via ' + last_method;
    })
  });
});