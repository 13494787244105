define('cmp-dashboard/components/shared/helpers/date-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'date-picker',

    didInsertElement: function didInsertElement() {
      this.set('tempFullDate', new Date());
    },


    day: Ember.computed('fullDate', function () {
      return this.get('fullDate').getDate();
    }),
    month: Ember.computed('fullDate', function () {
      return this.get('fullDate').getMonth();
    }),

    year: Ember.computed('fullDate', function () {
      return this.get('fullDate').getFullYear();
    }),

    tempDay: Ember.computed('tempFullDate', {
      get: function get() {
        return this.get('tempFullDate').getDate();
      },
      set: function set(key, value) {
        if (parseInt(value) === 'NaN') {
          value = new Date().getDate();
        }
        var date = new Date(this.get('tempFullDate').getFullYear(), this.get('tempFullDate').getMonth(), value);
        this.set('tempFullDate', date);
        return value;
      }
    }),

    tempMonth: Ember.computed('tempFullDate', {
      get: function get() {
        return this.get('tempFullDate').getMonth();
      },
      set: function set(key, value) {
        if (parseInt(value) === 'NaN') {
          value = new Date().getMonth();
        }
        var date = new Date(this.get('tempFullDate').getFullYear(), value, this.get('tempFullDate').getDate());
        this.set('tempFullDate', date);
        return value;
      }
    }),

    tempYear: Ember.computed('tempFullDate', {
      get: function get() {
        return this.get('tempFullDate').getFullYear();
      },
      set: function set(key, value) {
        if (parseInt(value) === 'NaN') {
          value = new Date().getFullYear();
        }
        var date = new Date(value, this.get('tempFullDate').getMonth(), this.get('tempFullDate').getDate());
        this.set('tempFullDate', date);
        return value;
      }
    }),

    formatted_month: Ember.computed('tempFullDate', function () {
      return (0, _moment.default)(this.get('tempFullDate')).format('MMMM');
    }),

    calendarRows: Ember.computed('tempFullDate', function () {
      var calendarRows = [];
      var month = this.get('tempMonth');
      var date = new Date(this.get('tempYear'), month, 1);
      var firstRowSet = false;

      while (date.getMonth() === month) {
        var row = [];
        for (var i = 0; i < 7; i++) {
          if (date.getDay() === i && date.getMonth() === month) {
            row.push(date.getDate());
            date.setDate(date.getDate() + 1);
          } else if (!firstRowSet || date.getMonth() !== month) {
            row.push('');
          }
        }
        calendarRows.push(row);
      }
      return calendarRows;
    }),

    actions: {
      lastMonth: function lastMonth() {
        var month = parseInt(this.get('tempMonth'));
        var year = parseInt(this.get('tempYear'));

        if (month === 0) {
          this.set('tempMonth', 11);
          this.set('tempYear', year - 1);
        } else {
          this.set('tempMonth', month - 1);
        }
      },
      nextMonth: function nextMonth() {
        var month = parseInt(this.get('tempMonth'));
        var year = parseInt(this.get('tempYear'));

        if (month === 11) {
          this.set('tempMonth', 0);
          this.set('tempYear', year + 1);
        } else {
          this.set('tempMonth', month + 1);
        }
      },
      selectDate: function selectDate(value) {
        this.set('tempDay', value);

        var selectedDate = new Date(this.get('tempYear'), this.get('tempMonth'), this.get('tempDay'), this.get('fullDate').getHours(), this.get('fullDate').getMinutes(), 0, 0);

        this.set('fullDate', selectedDate);
      }
    }
  });
});