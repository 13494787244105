define('cmp-dashboard/components/shared/popup/popup-modal-savebutton', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    actions: {
      onSubmit: function onSubmit() {
        // Call parent action
        this.get('onSave')();

        // Get isValid property from parent to see if it's valid and then close the modal
        if (this.get('parent').get('isValid')) {
          Ember.$('.modal-backdrop').addClass('out').removeClass('in');
          Ember.$('.modal-backdrop').remove();
          Ember.$('body').removeClass('modal-open');
        }
      }
    }
  });
});