define('cmp-dashboard/models/claimt', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    claim_register_id: _emberData.default.attr('number'),
    claim_date: _emberData.default.attr('utc'),
    ro_no: _emberData.default.attr('string'),
    mileage: _emberData.default.attr('number'),
    amt: _emberData.default.attr('number'),
    upsale: _emberData.default.attr('number'),
    enter_date: _emberData.default.attr('utc'),
    s_dealers_id: _emberData.default.attr('number'),
    reminder_update: _emberData.default.attr('string'),
    claim_update: _emberData.default.attr('string'),
    webcp: _emberData.default.attr('string'),
    scancp: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),
    dms_ronum: _emberData.default.attr('string'),
    historical: _emberData.default.attr('number'),
    transfer_claim: _emberData.default.attr('number'),
    coupon_id: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    contracts_f_id: _emberData.default.belongsTo('contractf', { async: true }),
    coupon: _emberData.default.belongsTo('coupon', { async: true }),

    customer_pay: Ember.computed('amt', 'upsale', function () {
      var amount = parseFloat(this.get('amt'));
      var upsale = parseFloat(this.get('upsale'));

      if (!amount) {
        amount = 0;
      }
      if (!upsale) {
        upsale = 0;
      }

      return amount + upsale;
    }),

    formatted_customer_pay: Ember.computed('customer_pay', function () {
      var customer_pay = parseFloat(this.get('customer_pay'));

      if (!customer_pay) {
        customer_pay = 0;
      }

      return '$' + customer_pay.toFixed(2);
    }),

    formatted_amt: Ember.computed('amt', function () {
      var price = parseFloat(this.get('amt'));

      if (!price) {
        price = 0;
      }

      return '$' + price.toFixed(2);
    }),

    formatted_upsale: Ember.computed('upsale', function () {
      var price = parseFloat(this.get('upsale'));

      if (!price) {
        price = 0;
      }

      return '$' + price.toFixed(2);
    }),

    formatted_claim_date: Ember.computed('claim_date', function () {
      return moment(this.get('claim_date')).format('MM/DD/YYYY');
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date')).format('MM/DD/YYYY');
    }),

    status_title: Ember.computed('formatted_enter_date', 'web_users_id.{name,dealers_id.name}', function () {

      var title = '\n<strong>Enter Date:</strong> ' + this.get('formatted_enter_date') + '\n<strong>Entered by:</strong> ' + this.get('web_users_id.name');

      if (this.get('web_users_id.dealers_id.id')) {
        title += ' - ' + this.get('web_users_id.dealers_id.name');
      }

      return title;
    })
  });
});