define('cmp-dashboard/models/reminder', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contracts_id: _emberData.default.attr('number'),
    reminder_date: _emberData.default.attr('date'),
    reminder_week_date: _emberData.default.attr('date'),
    coupon_id: _emberData.default.belongsTo('coupon', { async: true }),
    claim_date: _emberData.default.attr('date'),
    call_date: _emberData.default.attr('date'),
    reminder_type: _emberData.default.attr('string'),
    reminder_method: _emberData.default.attr('string'),

    reminder_date_formatted: Ember.computed('reminder_date', function () {
      return moment(this.get('reminder_date')).format('MM/DD/YYYY');
    }),

    reminder_week_date_formatted: Ember.computed('reminder_week_date', function () {
      return moment(this.get('reminder_week_date')).format('MM/DD/YYYY hh:mm A');
    }),

    claim_date_formatted: Ember.computed('claim_date', function () {
      return moment(this.get('claim_date')).format('MM/DD/YYYY hh:mm A');
    }),

    call_date_formatted: Ember.computed('call_date', function () {
      return moment(this.get('call_date')).format('MM/DD/YYYY hh:mm A');
    }),

    method: Ember.computed('reminder_method', function () {
      switch (this.get('reminder_method')) {
        case 'PC':
          return 'Post Card';
        case 'EML':
          return 'Email';
        case 'TXT':
          return 'Text Message';
        case 'VM':
          return 'Voice Message';
        default:
          return 'No Reminder Sent/Unable To Determine';
      }
    })
  });
});