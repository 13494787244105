define("cmp-dashboard/components/dashboard/layouts/service-layout", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    tagName: "section",
    elementId: "dashboard-cards",
    classNames: "cards",

    active_contracts_loading: false,
    lost_opportunities_loading: false,
    service_reminders_loading: false,
    claims_loading: false,
    contracts_sold_loading: false,

    // Link URLs
    active_contracts_link: "/reports/#/reports/contract_reserve",
    lost_opportunities_link: "/service/#/lost-opportunities",
    service_reminders_link: "/service/#/service-reminders",
    claims_link: "/reports/#/reports/repair_orders_by_month",
    contracts_sold_link: "/reports/#/reports/after_sales_summary",

    didReceiveAttrs: function didReceiveAttrs() {
      // Set default values for the cards
      this.set("active_contracts_data", []);
      this.set("lost_opportunity_data", []);
      this.set("claims_data", []);
      this.set("service_reminders_data", []);
      this.set("contracts_sold_data", []);

      this.setCardValues();
    },
    setCardValues: function setCardValues() {
      this.setActiveContracts();
      this.setLostOpportunities();
      this.setServiceReminders();
      this.setClaims();
      this.setContractsSold();
    },


    // get data for dealer's active contracts
    setActiveContracts: function setActiveContracts() {
      var self = this;

      this.set("active_contracts_loading", true);
      this.get("ajax").request("/reports/" + localStorage.dealer + "/executive_report/total_contracts").then(function (total_contracts) {
        var active_contracts = [];
        active_contracts.push({
          value: total_contracts.data[0][1],
          label: "Active Contracts",
          sublabel: "Current"
        });

        if (!self.get('isDestroyed')) {
          self.set("active_contracts_loading", false);
          self.set("active_contracts_data", active_contracts);
        }
      });
    },


    // get data for lost opportunities
    setLostOpportunities: function setLostOpportunities() {
      var self = this;

      this.set("lost_opportunities_loading", true);
      this.get("ajax").request("/reports/" + localStorage.dealer + "/lost_opportunities/monthly").then(function (lost_opportunities) {
        var newLOs = 0;
        var callsMade = 0;
        var emailsSent = 0;
        var textsSent = 0;

        // Get the new lost opportunities from last month
        lost_opportunities.data.newLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            newLOs = lost_op[1];
          }
        });

        // Get the lost opportunity calls from last month
        lost_opportunities.data.callLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            callsMade = lost_op[1];
          }
        });

        // Get the lost opportunity emails from last month
        lost_opportunities.data.emailLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            emailsSent = lost_op[1];
          }
        });

        // Get the lost opportunity texts from last month
        lost_opportunities.data.textLO.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            textsSent = lost_op[1];
          }
        });

        // Setup lost opportunity data array
        var lost_opportunities_data = [];
        lost_opportunities_data.push({
          value: newLOs ? newLOs : 0,
          label: "New Lost Opportunities",
          sublabel: "Last Month"
        });
        lost_opportunities_data.push({
          value: callsMade ? callsMade : 0,
          label: "Calls Made",
          sublabel: "Last Month"
        });
        lost_opportunities_data.push({
          value: emailsSent ? emailsSent : 0,
          label: "Emails Sent",
          sublabel: "Last Month"
        });
        lost_opportunities_data.push({
          value: textsSent ? textsSent : 0,
          label: "Texts Sent",
          sublabel: "Last Month"
        });

        if (!self.get('isDestroyed')) {
          self.set("lost_opportunities_loading", false);
          self.set("lost_opportunity_data", lost_opportunities_data);
        }
      });
    },


    // get data for service reminders
    setServiceReminders: function setServiceReminders() {
      var self = this;

      this.set("service_reminders_loading", true);
      this.get("ajax").request("/reports/" + localStorage.dealer + "/service_reminders/monthly").then(function (service_reminders) {
        var postCardsSent = 0;
        var callsMade = 0;
        var emailsSent = 0;
        var textsSent = 0;

        // Get the service reminder post cards sent from last month
        service_reminders.data.total_post_card.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            postCardsSent = lost_op[1];
          }
        });

        // Get the service reminder calls from last month
        service_reminders.data.total_call.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            callsMade = lost_op[1];
          }
        });

        // Get the service reminder emails from last month
        service_reminders.data.total_email.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            emailsSent = lost_op[1];
          }
        });

        // Get the service reminder texts from last month
        service_reminders.data.total_text.forEach(function (lost_op) {
          var month = (0, _moment.default)(lost_op[0]).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            textsSent = lost_op[1];
          }
        });

        // Setup service reminder data array
        var service_reminders_data = [];
        service_reminders_data.push({
          value: callsMade ? callsMade : 0,
          label: "Reminder Calls Made",
          sublabel: "Last Month"
        });
        service_reminders_data.push({
          value: emailsSent ? emailsSent : 0,
          label: "Reminder Emails Sent",
          sublabel: "Last Month"
        });
        service_reminders_data.push({
          value: textsSent ? textsSent : 0,
          label: "Reminder Texts Sent",
          sublabel: "Last Month"
        });
        service_reminders_data.push({
          value: postCardsSent ? postCardsSent : 0,
          label: "Reminder Cards Sent",
          sublabel: "Last Month"
        });

        if (!self.get('isDestroyed')) {
          self.set("service_reminders_loading", false);
          self.set("service_reminders_data", service_reminders_data);
        }
      });
    },


    // Get inception to date claims data
    setClaims: function setClaims() {
      var self = this;

      this.set("claims_loading", true);
      this.get("ajax").request("/reports/" + localStorage.dealer + "/repair_orders_by_month/visits?start_date=2000-01-01").then(function (visits) {
        self.get("ajax").request("/reports/" + localStorage.dealer + "/repair_orders_by_month/claims?start_date=2000-01-01").then(function (income) {
          var itd_visits = 0;
          var itd_income = 0;

          // Add all visits data
          visits.data.forEach(function (month) {
            itd_visits += month.y;
          });

          // Add all income data
          income.data.forEach(function (month) {
            itd_income += month.y;
          });

          // Setup claims data array
          var claims_data = [];
          claims_data.push({
            value: itd_visits,
            label: "Claim Visits",
            sublabel: "ITD"
          });
          claims_data.push({
            value: "$" + (itd_income ? itd_income.toFixed(2) : '0'),
            label: "Claim Income",
            sublabel: "ITD"
          });

          if (!self.get('isDestroyed')) {
            self.set("claims_loading", false);
            self.set("claims_data", claims_data);
          }
        });
      });
    },


    // Get contracts sold by last month & year-to-date
    setContractsSold: function setContractsSold() {
      var self = this;

      this.set("contracts_sold_loading", true);
      this.get("ajax").request("/reports/" + localStorage.dealer + "/service_activity/after_sale_sales").then(function (contracts_sold) {
        self.set("contracts_sold_loading", false);

        var last_month_sold = 0;
        var YTD = 0;

        // Get the contracts sold for each type of schedule (complimentary, upgrade, after_sale)

        contracts_sold.data.forEach(function (contract) {
          var current_year = (0, _moment.default)().year();
          var contract_year = (0, _moment.default)(contract.result_date).year();
          var month = (0, _moment.default)(contract.result_date).month();
          var last_month = (0, _moment.default)().subtract(1, "months").month();

          if (month === last_month) {
            last_month_sold++;
          }

          if (contract_year === current_year) {
            YTD++;
          }
        });

        // Setup contracts sold data array
        var contracts_sold_data = [];
        contracts_sold_data.push({
          value: last_month_sold ? last_month_sold : 0,
          label: "Contracts Sold",
          sublabel: "Last Month"
        });
        contracts_sold_data.push({
          value: YTD ? YTD : 0,
          label: "Contracts Sold",
          sublabel: "YTD"
        });

        if (!self.get('isDestroyed')) {
          self.set("contracts_sold_loading", false);
          self.set("contracts_sold_data", contracts_sold_data);
        }
      });
    }
  });
});