define('cmp-dashboard/models/contractf', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fname: _emberData.default.attr('string'),
    lname: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    mobile_number: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    car_year: _emberData.default.attr('string'),
    car_make: _emberData.default.attr('string'),
    car_model: _emberData.default.attr('string'),
    car_used: _emberData.default.attr('string'),
    vin: _emberData.default.attr('string'),
    vin8: _emberData.default.attr('string'),
    odo: _emberData.default.attr('number'),
    salesman_id: _emberData.default.attr('string'),
    enter_date: _emberData.default.attr('utc'),
    next_app_date: _emberData.default.attr('utc'),
    purchase_date: _emberData.default.attr('utc'),
    manual_appt_date: _emberData.default.attr('number'),
    external_ref_id: _emberData.default.attr('string'),
    external_ref_source: _emberData.default.attr('string'),
    child_external_ref_id: _emberData.default.attr('string'),
    dms_customer_number: _emberData.default.attr('string'),
    dms_deal_number: _emberData.default.attr('string'),
    dms_stock_number: _emberData.default.attr('string'),
    customer_price: _emberData.default.attr('number'),
    imported: _emberData.default.attr('string'),
    finished: _emberData.default.attr('string'),
    transferred: _emberData.default.attr('string'),
    upgrade_from_contracts_id: _emberData.default.attr('number'),
    initial_loyalty_balance: _emberData.default.attr('number'),
    lienholder_name: _emberData.default.attr('string'),
    lienholder_tel: _emberData.default.attr('string'),
    lienholder_address: _emberData.default.attr('string'),
    lienholder_city: _emberData.default.attr('string'),
    lienholder_state: _emberData.default.attr('string'),
    lienholder_zip: _emberData.default.attr('string'),

    after_sale: _emberData.default.attr('number'),

    customers_id: _emberData.default.attr('number'),
    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contract_register_f_id: _emberData.default.belongsTo('contractregisterf', { async: true }),
    dealers_sched_id: _emberData.default.belongsTo('sched', { async: true }),

    claimts: _emberData.default.hasMany('claimt', {
      async: true
    }),

    dealer_name: Ember.computed.alias('dealers_id.name'),

    /**
     * Combine first name and last name
     *
     * @return string
     */
    fullName: Ember.computed('fname', 'lname', function () {
      return this.get('fname') + ' ' + this.get('lname');
    }),

    sale_price: Ember.computed('dealers_sched_id.sale_price', function () {
      return this.get('dealers_sched_id').get('sale_price');
    }),
    dealer_remittance: Ember.computed('dealers_sched_id.dealer_remittance', function () {
      return this.get('dealers_sched_id').get('dealer_remittance');
    }),
    formatted_enter_date: Ember.computed('enter_date', function () {
      var date = this.get('enter_date');
      return moment(date).format('MM/DD/YYYY');
    }),
    formatted_exp_date: Ember.computed('exp_date', function () {
      var date = this.get('exp_date');
      return moment(date).format('MM/DD/YYYY');
    }),

    formatted_purchase_date: Ember.computed('purchase_date', function () {
      var date = this.get('purchase_date');
      return moment(date).format('MM/DD/YYYY');
    }),

    formatted_customer_price: Ember.computed('customer_price', function () {
      var customer_price = parseFloat(this.get('customer_price'));

      if (!customer_price) {
        customer_price = 0;
      }

      return '$' + customer_price.toFixed(2);
    }),

    formatted_tel: Ember.computed('tel', function () {
      if (this.get('tel') && this.get('tel') !== '') {
        return this.get('tel').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }

      // No telephone
      return null;
    }),

    formatted_mobile_number: Ember.computed('mobile_number', function () {
      if (this.get('mobile_number') && this.get('mobile_number') !== '') {
        return this.get('mobile_number').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }),

    formatted_car_used: Ember.computed('car_used', function () {
      return this.get('car_used') === 'N' ? 'New' : 'Used';
    }),

    /**
     * Combine car information
     *
     * @return string
     */
    car: Ember.computed('car_year', 'car_make', 'car_model', function () {
      if (this.get('car_year') && this.get('car_make') && this.get('car_model')) {
        return this.get('car_year') + ' ' + this.get('car_make') + ' ' + this.get('car_model');
      }

      // No car??
      return null;
    }),

    service_drive_contract: Ember.computed('dealers_sched_id.renew', function () {
      return this.get('dealers_sched_id').get('renew');
    })
  });
});