define('cmp-dashboard/components/shared/helpers/tools/tooltip-overlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isOpen: false,

    didInsertElement: function didInsertElement() {
      var self = this;

      Ember.run.later(function () {
        self.set('isOpen', true);
      }, 50);
    },
    click: function click() {
      var self = this;

      this.set('isOpen', false);
      Ember.run.later(function () {
        self.get('closeTooltip')();
      }, 500);
    },
    contextMenu: function contextMenu() {
      var self = this;

      this.set('isOpen', false);
      Ember.run.later(function () {
        self.get('closeTooltip')();
      }, 500);
    }
  });
});