define('cmp-dashboard/models/searchdealer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    _id: _emberData.default.attr(),

    groups_id: _emberData.default.attr(),
    agents_id: _emberData.default.attr(),
    reps_id: _emberData.default.attr(),
    name: _emberData.default.attr(),
    address1: _emberData.default.attr(),
    address2: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    zip: _emberData.default.attr(),
    zip2: _emberData.default.attr(),
    tel: _emberData.default.attr(),
    tel_ex: _emberData.default.attr(),
    fax: _emberData.default.attr(),
    owner: _emberData.default.attr()
  });
});