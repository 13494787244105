define('cmp-dashboard/models/claim', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mileage: _emberData.default.attr(),
    amt: _emberData.default.attr('number'),

    coupon_id: _emberData.default.attr('number'),
    code: _emberData.default.attr('string'),
    claim_register_id: _emberData.default.attr('number'),
    claim_date: _emberData.default.attr('utc'),
    ro_no: _emberData.default.attr('string'),
    upsale: _emberData.default.attr('number'),
    enter_date: _emberData.default.attr('utc'),
    s_dealers_id: _emberData.default.attr('number'),
    reminder_update: _emberData.default.attr('string'),
    claim_update: _emberData.default.attr('string'),
    webcp: _emberData.default.attr('string'),
    scancp: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    dup: _emberData.default.attr('string'),
    dms_upsale: _emberData.default.attr('number'),
    dms_ronum: _emberData.default.attr('string'),
    historical: _emberData.default.attr('number'),
    transfer_claim: _emberData.default.attr('number'),

    dealers_id: _emberData.default.belongsTo('dealer', { async: true }),
    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),
    claimregister: _emberData.default.belongsTo('claimregister', { async: true }),
    coupon: _emberData.default.belongsTo('coupon', { async: true }),

    formatted_amt: Ember.computed('amt', function () {
      var price = this.get('amt');

      if (!price) {
        price = 0;
      }

      return '$' + price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),

    formatted_upsale: Ember.computed('upsale', function () {
      var price = this.get('upsale');

      if (!price) {
        price = 0;
      }

      return '$' + price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }),

    formatted_claim_date: Ember.computed('claim_date', function () {
      return moment(this.get('claim_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    formatted_enter_date: Ember.computed('enter_date', function () {
      return moment(this.get('enter_date'), 'YYYY-MM-DD').format('MM/DD/YYYY');
    }),

    status_title: Ember.computed('claimregister.{id,formatted_enter_date}', 'formatted_enter_date', 'web_users_id.{name,dealers_id.name}', function () {
      var title = '';

      if (this.get('claimregister.id')) {
        title += '\n<strong>Claim Register #:</strong>' + this.get('claimregister.id') + '\n<strong>Claim Register Date:</strong> ' + this.get('claimregister.formatted_enter_date');
      }

      title += '\n<strong>Enter Date:</strong> ' + this.get('formatted_enter_date') + '\n<strong>Entered by:</strong> ' + this.get('web_users_id.name');

      if (this.get('web_users_id.dealers_id.id')) {
        title += ' - ' + this.get('web_users_id.dealers_id.name');
      }

      return title;
    })
  });
});