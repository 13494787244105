define('cmp-dashboard/models/inst', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    zip2: _emberData.default.attr('string'),
    tel: _emberData.default.attr('string'),
    tel_ex: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    c_fname: _emberData.default.attr('string'),
    c_lname: _emberData.default.attr('string'),
    c_email: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('date'),
    managed_internally: _emberData.default.attr('number'),
    remit_sponsor_comm_pac: _emberData.default.attr('number'),
    remit_agent_comm_pac: _emberData.default.attr('number'),
    remit_tp_cost_pac: _emberData.default.attr('number'),
    remit_tp_cost: _emberData.default.attr('number'),
    remit_passthru_pac: _emberData.default.attr('number'),
    remit_reserve_pac: _emberData.default.attr('number'),
    remit_reserve: _emberData.default.attr('number'),
    show_reserve: _emberData.default.attr('number'),
    hide_cl_reg: _emberData.default.attr('number'),
    claim_clearing_house: _emberData.default.attr('number'),
    dealer_bank_agent: _emberData.default.attr('number'),
    qb_vendor_refnum: _emberData.default.attr('number'),
    qb_customer_refnum: _emberData.default.attr('number'),
    qb_accnt_refnum: _emberData.default.attr('number'),
    dealer_remit_to_company: _emberData.default.attr('string'),
    dealer_remit_to_city: _emberData.default.attr('string'),
    dealer_remit_to_state: _emberData.default.attr('string'),
    dealer_remit_to_address: _emberData.default.attr('string'),
    dealer_remit_to_apt: _emberData.default.attr('string'),
    dealer_remit_to_zip: _emberData.default.attr('string'),
    receive_cancellation: _emberData.default.attr('number'),
    print_cancellation: _emberData.default.attr('string'),
    prorate_cancellation: _emberData.default.attr('number'),

    sponsors_id: _emberData.default.belongsTo('sponsor', { async: true })
  });
});