define('cmp-dashboard/models/contractcomment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    comment: _emberData.default.attr('string'),
    created: _emberData.default.attr('date'),

    contracts_id: _emberData.default.belongsTo('contract', { async: true }),
    web_users_id: _emberData.default.belongsTo('user', { async: true }),

    formatted_created: Ember.computed('created', function () {
      return moment(this.get('created')).format('MM/DD/YYYY hh:mm:ss a');
    })
  });
});