define('cmp-dashboard/components/shared/layout/sidenav/nav-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),

    classNames: 'nav-section__item',
    classNameBindings: ['navOpen::nav-section__item--sidebar-closed', 'item.active:nav-section__item--active', 'showSubItems:nav-section__item--open', 'item.subItems:nav-section__item--dropdown'],

    tooltipLeft: 68,
    showSubItems: false,

    mouseEnter: function mouseEnter() {
      if (!this.get('navOpen')) {
        var item = this.$();

        if (!item) {
          return;
        }

        var styleTop = parseInt(item[0].offsetTop) + 7; // Top for tooltips. Magic number is (item height - tooltip height) / 2.

        this.set('styleTop', styleTop);
        this.set('showTooltip', true);
      }
    },
    mouseLeave: function mouseLeave() {
      this.set('showTooltip', false);
    },
    click: function click() {
      if (this.get('item.subItems')) {
        this.send('toggleSubItems');
      }
    },


    isVisible: Ember.computed('item.{hiddenFor,visibleFor,userPermission}', 'application.user.{role,permissions_user}', function () {
      var hiddenFor = this.get('item.hiddenFor');
      var visibleFor = this.get('item.visibleFor');
      var permission = this.get('item.userPermission');
      var role = this.get('application.user.role');
      var userPermissions = this.get('application.user.permissions_user');
      var visible = true;

      if (hiddenFor && hiddenFor.includes(role)) {
        visible = false;
      }

      if (visibleFor && !visibleFor.includes(role)) {
        visible = false;
      }

      if (permission && userPermissions) {
        var userPerm = userPermissions.find(function (userPermission) {
          return userPermission.permission_id === 6;
        });

        visible = userPerm ? true : false;
      }

      return visible;
    }),

    // Computes where to place the tooltip or menu
    // when displayed
    styleTop: Ember.computed('navOpen', function () {
      var self = this;

      if (!this.get('navOpen')) {
        Ember.run.later(function () {
          var item = self.$();

          if (!item) {
            return;
          }

          var styleTop = parseInt(item[0].offsetTop) + 7; // Top for tooltips. Magic number is (item height - tooltip height) / 2.

          return styleTop;
        }, 1000);
      }
    }),

    // Observes the nav being open or closed
    // If the subItem menu is open when the nav
    // changed to closed, we want to close the submenu
    // so that we don't have multiple menus open at once
    navClose: Ember.observer('navOpen', function () {
      var navOpen = this.get('navOpen');
      var subItems = this.get('showSubItems');

      if (!navOpen && subItems) {
        this.set('showSubItems', false);
      }
    }),

    actions: {
      toggleSubItems: function toggleSubItems() {
        this.toggleProperty('showSubItems');
      },
      closeSubItems: function closeSubItems() {
        this.set('showSubItems', false);
        this.set('showTooltip', false);
      }
    }
  });
});