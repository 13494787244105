define('cmp-dashboard/components/shared/helpers/tools/keypress-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      var self = this;

      Ember.$(window).on('keyup', function (e) {
        if (e.target.nodeName === 'INPUT') {
          return;
        }

        var page = parseInt(self.get('model').get('page'));

        if (e.keyCode === 37 && page !== 1) {
          self.get('pageClicked')(page - 1);
        }
        if (e.keyCode === 39 && page !== self.get('model').get('totalPages')) {
          self.get('pageClicked')(page + 1);
        }
      });
    },
    willDestroy: function willDestroy() {
      this._super();
      Ember.$(window).off('keydown');
    }
  });
});