define('cmp-dashboard/components/shared/layout/sidenav/nav-items-section', ['exports', 'cmp-dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    application: Ember.inject.service(),
    tagName: 'section',
    classNames: 'nav-section',

    didInsertElement: function didInsertElement() {
      this.set('env', _environment.default.environment);
    },


    isVisible: Ember.computed('section.{hiddenFor,visibleFor}', 'application.user.role', function () {
      var hiddenFor = this.get('section.hiddenFor');
      var visibleFor = this.get('section.visibleFor');
      var role = this.get('application.user.role');

      if (hiddenFor) {
        return !hiddenFor.includes(role);
      }

      if (visibleFor) {
        return visibleFor.includes(role);
      }

      return true;
    })
  });
});