define('cmp-dashboard/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    application: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this.get('ajax').request('/api').then(function () {}, function (error) {
        if (error.status === 0) {
          document.getElementById('app-error').innerHTML = '<h1>Application Error: Could not connect to API.</h1>';
          document.getElementById('app').removeChild(document.getElementsByClassName('ember-view')[0]);
        }
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      var self = this;

      if (localStorage.dealer && this.get('session').get('isAuthenticated')) {
        this.store.findRecord('dealer', localStorage.dealer).then(function (dealer) {
          controller.set('dealer', dealer);
          self.get('application').set('dealer', dealer);
        });
      }

      if (this.get('session').get('isAuthenticated')) {
        this.store.query('user', { who: 1 }).then(function (users) {
          var user = users.get('firstObject');
          localStorage.email = user.get('email');
          controller.set('user', user);
          self.get('application').set('user', user);
        });
      }
    },


    actions: {
      error: function error(err) {
        var isSetup = window.location.hash.indexOf('setup') !== -1;
        var current = this;
        if (err.errors) {
          err.errors.forEach(function (e) {
            console.log(e.status);
            if (e.status === '403' && !isSetup) {

              // If statement needed for glitch (visiting pages too often: acceptance-tests)
              if (current.get('session.isAuthenticated')) {
                current.get('session').invalidate();
              } else {
                window.location.replace("/login");
              }
            } else if (e.status === '400') {
              console.log('model not found');
            }
          });
        } else {
          console.log(err);
        }
      },
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      forbiddenRoute: function forbiddenRoute() {
        this.transitionTo('forbidden');
      }
    }
  });
});